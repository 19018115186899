/**
 * Created by Vit on 02.06.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

const classes = new Bem('loaderScreen');

/**
 * === LoaderDots Component ===
 * Отображение загрузки всего приложения на главном экране
 */

export default class LoaderDots extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        {...classes({
          extra: this.props.className
        })}
      >
        <div {...classes('logoDots')}>
          <ul {...classes('progress', 'new')}>
            <li {...classes('progressItem')}>
              <span {...classes('ball', '1')} />
            </li>
            <li {...classes('progressItem')}>
              <span {...classes('separator')} />
            </li>
            <li {...classes('progressItem')}>
              <span {...classes('ball', '2')} />
            </li>
            <li {...classes('progressItem')}>
              <span {...classes('separator')} />
            </li>
            <li {...classes('progressItem')}>
              <span {...classes('ball', '3')} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
