import React, { Component } from 'react';
import { withRouter, locationShape, /* , routerShape */ } from 'react-router';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Block, { BlockContent } from 'components/block';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

import UFO from './assets/cow.jpg';

import ContentBild from './content-bild';
import ContentBildcontrol from './content-bildcontrol';
import ContentA2 from './content-a2';
import ContentDomik from './content-domik';
import ContentForum from './content-forum';

import translates from './not-found-i18n.json';

import 'styles/base/containers/not-found/_not-found.scss';

const contents = {
  bild: ContentBild,
  bildcontrol: ContentBildcontrol,
  a2: ContentA2,
  domik: ContentDomik,
  forum: ContentForum,
};

const bemClasses = new Bem('notFound');

@withRouter
@I18nHoc(translates)
export default class NotFoundContent extends Component {
  static propTypes = {
    location: locationShape/* ,
    router: routerShape,
    history: PropTypes.node */
  };
  /* renderBackLink() {
     if (this.props.history.length > 0) {
      return (<Link onClick={this.props.router.goBack}>И Вы можете вернуться назад...</Link>);
    }
    return (<Link to="/">И Вы можете вернуться на главную страницу...</Link>);
  } */

  render() {
    const proj = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
    if (contents[proj]) {
      const CComponent = contents[proj];
      return <CComponent />;
    }

    const { i18n } = this;
    const location = this.props.location.pathname;

    return (
      <div {...bemClasses()}>
        <Block {...bemClasses('block')}>
          <BlockContent {...bemClasses('center')}>
            <Link {...bemClasses('linkLogo')} href="/">
              <Icon {...bemClasses('linkLogoIcon')} type={iconTypes.logoDomik} />
            </Link>
            <p {...bemClasses('row')}>
              <span {...bemClasses('bold')}>404.</span> <span>{i18n('defaultError404', { placeholder: <code>{location}</code> })}</span>
            </p>
            <p {...bemClasses('row')}>
              <span>{i18n('defaultError404_1')}</span>
            </p>
            <p {...bemClasses('row')}>
              <Link href="/">{i18n('defaultToMain')}</Link>
            </p>
            <p>
              <img src={UFO} alt="404 not found" loading="lazy" decoding="async" />
            </p>
          </BlockContent>
        </Block>
      </div>
    );
  }
}
