import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, match, browserHistory, createMemoryHistory } from 'react-router'; // Router
import { syncHistoryWithStore } from 'react-router-redux';
import { preloadExecutor } from 'api/data-preload';
import useScroll from 'scroll-behavior/lib/useStandardScroll';
import { SensorProvider } from 'components/sensor';
import NotificationProvider from 'components/notification';
import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
import { loadableReady } from '@loadable/component';
import 'requestidlecallback-polyfill';

// import './helpers/browser-polyfills';
// import { ReactCookiesProvider } from './helpers/react/ReactCookiesProvider';
import ProjectConfig from '../config';
import ReactApiProvider from './helpers/react/ReactApiProvider';
import configureStore from './helpers/redux/configure-store';
import { setAccessStore } from './helpers/redux/access-store';
import ApiClient from './api/api-client';
import xsrfTokenMiddleware from './api/middlewares/xsrf-token-middleware';
import setLangMiddleware from './api/middlewares/set-lang-middleware';
import defaultMiddleware from './api/middlewares/default-middleware';
import xorMiddleware from './api/middlewares/xor-middleware';
import { Provider as ReactUniqueIdProvider } from './helpers/react/ReactUniqueIds';
import ReduxUpdateStore from './redux-update-store';

function getBrowser() {
  if (!navigator) return {};

  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      tem = tem.slice(1);
      return { name: tem[0].replace('OPR', 'Opera'), version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) M.splice(1, 1, tem[1]);

  return { name: M[0], version: M[1] };
}

/**
 * Register Sentry error handler and
 * global react component error handler
 */
function registerSentry() {
  if (ProjectConfig.env === 'local') {
    return;
  }

  const ignoreErrors = [
    /code.*404/i,
    /code.*502/i,
    /class not exists/i,
    /network error/i,
    /page is iframe/i,
    /timeout of 60000/i
  ];

  const curBrws = getBrowser();
  if (curBrws.name === 'IE') return;

  Sentry.init({
    dsn: ProjectConfig.sentryPoint,
    release: __RELEASE__,
    environment: ProjectConfig.env,
    attachStacktrace: true,
    integrations: [
      new Sentry.Integrations.GlobalHandlers(),
      new Sentry.Integrations.TryCatch(),
      new SentryIntegrations.CaptureConsole({ levels: ['error'] }),
      new SentryIntegrations.ExtraErrorData({ depth: 15 })
    ],
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      const message = error && error.message;
      if (message) {
        for (let i = 0; i <= ignoreErrors.length - 1; i += 1) {
          if (message.match(ignoreErrors[i])) {
            return;
          }
        }
      }
      return event;
    }
  });

  Sentry.configureScope((scope) => {
    scope.setTag('side', 'client');
    scope.setTag('project', __PROJECT__); // eslint-disable-line
  });

  Component.prototype.unstable_handleError = (error) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('componentProps', this.props);
      scope.setExtra('componentState', this.state);
    });
    Sentry.captureException(error);
  };
}

function getPreloaded(id) {
  const el = document.getElementById(id);
  if (el) {
    const data = JSON.parse(el.getAttribute('data-json'));
    deleteElById(id);
    return data;
  }
  return null;
}

function processRoute(routes, params) {
  let ApiUrl = ProjectConfig.apiUrlFront;
  if (__APP__) ApiUrl = ProjectConfig.apiUrl;

  const api = new ApiClient(
    ApiUrl,
    xorMiddleware,
    xsrfTokenMiddleware(),
    setLangMiddleware(),
    defaultMiddleware
  );

  // eslint-disable-next-line
// const historyLib = (__APP__ ? hashHistory : browserHistory);
  let historyLib = browserHistory;
  if (__APP__) historyLib = createMemoryHistory(window.location);

  // const historyLib = browserHistory;
  const _browserHistory = useScroll(() => historyLib)();
  const dest = document.getElementById('content');
  const store = configureStore(_browserHistory, api,
    getPreloaded('__reduxStore') || undefined
  );

  setAccessStore(store);

  let history = null;
  try {
    history = syncHistoryWithStore(_browserHistory, store);
  } catch (e) {
    location.href = '/';
    return;
  }

  if (__DEVELOPMENT__) {
    // eslint-disable-next-line no-console
    if (ProjectConfig.env === 'local') console.info('Front-end application builded in Development mode.');
    window.React = React; // enable debugger
  }

  // eslint-disable-next-line
  const variables = require(`!!sass-to-js-var-loader!./styles/${params.cssVars}/variables-theme.scss`) || {};

  const provideMedia = {
    phone: variables.phone,
    tablet: variables.tablet,
    desktop: variables.desktopUp,
    phoneAndTablet: variables.phoneAndTablet
  };

  match({ history, routes }, (err, redirect, renderProps) => {
    if (renderProps) {
      Sentry.configureScope((scope) => {
        scope.setExtra('route', renderProps.location);
        scope.setExtra('routeParams', renderProps.params);
      });
    }

    // console.log('Client Render Match', renderProps); // eslint-disable-line
    if (err) {
      throw new Error(err);
    }

    if (redirect) {
      // TODO Implement client redirect
      // Результат консультации с вадосом.
      // С сервера когда такой запрос приходит - 301 редирект,
      // На клиенте тупо меняем урл и подменяем а не пушим историю, и дергаем гуглоаналитику.
      // throw new Error('CLIENT REDIRECT NOT IMPLEMENTED'); // Old behavior
      // New behaviro for implement slash replacer
      //history.push(redirect); // Not work... Stange...
      location.href = redirect.pathname; // eslint-disable-line
      return;
    }

    const ssrDataPreloaded = getPreloaded('__preloadString');

    if (renderProps) {
      const loadersSharedConfig = {
        api
      };

      return preloadExecutor(renderProps, loadersSharedConfig, ssrDataPreloaded)
        .then(() => {
          const component = (
            <SensorProvider {...{ provideMedia, variables }}>
              <ReactUniqueIdProvider>
                <ReactApiProvider api={api}>
                  <Provider store={store}>
                    <div>
                      <ReduxUpdateStore />
                      <Router {...renderProps} />
                      <NotificationProvider />
                      <div id="modals" />
                      <div id="fb-root" />
                    </div>
                  </Provider>
                </ReactApiProvider>
              </ReactUniqueIdProvider>
            </SensorProvider>
          );
          if (getPreloaded('__ssrFull')) {
            loadableReady(() => {
              console.log('React Hydrate'); // eslint-disable-line
              console.time('reactHydrate');
              ReactDOM.hydrate(component, dest);
              console.timeEnd('reactHydrate');
            });
          } else {
            console.log('React Render'); // eslint-disable-line
            dest.innerHTML = null;
            console.time('reactRender');
            ReactDOM.render(component, dest);
            console.timeEnd('reactRender');
          }
        });
    }
    // If some troubles - log an error
    throw new Error('Something wrong when client-side preloader executed - no renderProps for route');
  });
}

/**
 * Render and inject React-formed page
 * @param routes
 * @param params
 */
export default function renderPage(routes, params) {
  window.onload = () => {
    registerSentry();
    processRoute(routes, params);
  }
}

function deleteElById(id) {
  const element = document.getElementById(id);
  if (element) element.outerHTML = '';
}
