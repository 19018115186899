import filter from 'lodash/filter';
import each from 'lodash/each';
import ProjectConfig from '../../config';

/**
 * Server events subscriber
 * Subscribe and receive server events via websockets and run listeners
 */

const listeners = {};

let Client = null;

function wsConnect() {
  if (__CLIENT__) {
    Client = new WebSocket(`${ProjectConfig.domains.websocket}/ws/event-bus`);
    Client.onmessage = handleMessage;
    Client.onopen = handleConnected;
    Client.onclose = handleClose;
  }
}

/**
 * Handle messages from WebSockets server
 * @param msg
 */
function handleMessage(msg) {
  const data = JSON.parse(msg.data);
  if (__DEV_CLI__) {
    // eslint-disable-next-line
    console.log('Server message received', msg);
  }

  if (!data) {
    return;
  }
  if (data.proc === 'receive') {
    if (!listeners[data.event]) {
      return;
    } if (!listeners[data.event][data.id]) {
      return;
    }
    each(listeners[data.event][data.id], listener => listener(data.id, data.payload));
  }
}

function handleConnected() {
  each(listeners, (ids, event) =>
    each(ids, (fns, id) => subscribeOnServer(event, id)
    ));
}

function handleClose(evt) { // eslint-disable-line
  setTimeout(() => wsConnect(), 500);
}

function _sendWsMessage(proc, payload) {
  // Create a WS client if needed
  if (!Client) {
    wsConnect();
  }
  // Sic!
  if (!Client) {
    return;
  }

  if (Client.readyState !== Client.OPEN) {
    return;
  }
  Client.send(JSON.stringify({
    proc,
    ...payload
  }));
}

/**
 * Subscribe to messages on server
 * @param event
 * @param id
 */
function subscribeOnServer(event, id) {
  _sendWsMessage('subscribe', {
    event,
    id
  });
}

/**
 * Unsubscribe to messages on server
 * @param event
 * @param id
 */
function unsubscribeOnServer(event, id) {
  _sendWsMessage('unsubscribe', {
    event,
    id
  });
}

/**
 * Subscribe to server event
 * @param event
 * @param id
 * @param listener
 */
export function serverSubscribe(event, id, listener) {
  if (!listeners[event]) {
    listeners[event] = {};
  }
  if (!listeners[event][id]) {
    listeners[event][id] = [];
    subscribeOnServer(event, id);
  }
  listeners[event][id].push(listener);
}

/**
 * Unsubscribe from server event
 * @param event
 * @param id
 * @param listener
 */
export function serverUnsubscribe(event, id, listener) {
  if (!listeners[event]) {
    return;
  }
  if (!listeners[event][id]) {
    return;
  }

  listeners[event][id] = filter(listeners[event][id], val =>
    val !== listener);

  if (listeners[event][id].length === 0) {
    unsubscribeOnServer(event, id);
    delete listeners[event][id];
  }

  if (Object.keys(listeners[event]).length === 0) {
    delete listeners[event];
  }
}

/**
 * Publish event to server
 *
 * @param event
 * @param id
 * @param payload
 */
export function serverPublish(event, id, payload) {
  _sendWsMessage('message', { event, id, payload });
}

