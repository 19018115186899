function pushGtmEvent(event, data) {
  if (!window) return Promise.resolve();
  if (typeof window['google_tag_manager'] === 'undefined') return Promise.resolve(null); //eslint-disable-line

  return new Promise((accept) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event,
      ...data,
      version: __RELEASE__,
      eventTimeout: 2000,
      eventCallback: () => accept()
    });
  });
}

export function gtmHistoryChange(entity, entityId) {
  return pushGtmEvent('loaded.historyChange', {
    'loaded.content': entity,
    'loaded.id': entityId
  });
}

export function gtmSuccessError(entity, entityId) {
  return pushGtmEvent(entity, { ea: entityId });
}

export function gtmPush(entity, entityData) {
  return pushGtmEvent(entity, entityData);
}
