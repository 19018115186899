/**
 * Created by DryyMoon on 22.11.2016.
 */
import { Component, Children } from 'react';
import PropTypes from 'prop-types';
import SensorLib from './sensor';

class SensorProvider extends Component {
  static propTypes = {
    provideMedia: PropTypes.objectOf(PropTypes.string),
    variables: PropTypes.objectOf(PropTypes.string),
    children: PropTypes.node
  };

  static childContextTypes = {
    sensor: PropTypes.instanceOf(SensorLib)
  };

  constructor(props, context) {
    super(props, context);
    this.sensor = new SensorLib();
    if (props.provideMedia) this.sensor.addMediaQueries(props.provideMedia);
    if (props.variables) this.sensor.setVariables(props.variables);
  }

  getChildContext() {
    return { sensor: this.sensor };
  }

  render() {
    return Children.only(this.props.children);
  }
}

export default SensorProvider;
