import each from 'lodash/each';

/**
 * Push event to marketing statisticts
 * @param event - event from events.js
 * @param entity - entity in custom format
 * @param entityId - entity ID
 * @param data - advanced data (if needed)
 *
 * @return Promise - when we need a wait for all events push
 */
export default function push(event, entity, entityId, data = {}) {
  if (__SERVER__) return Promise.resolve(null);

  const promises = [];
  each(event.handlers, hdl => promises.push(hdl(entity, entityId, data)));

  return Promise.all(promises);
}
