import { landingRoute, i18n, slashCheck } from 'helpers/router';

import loadable from '@loadable/component';
import landingRoutes from './landing-routes';

export default function () { // eslint-disable-line
  return i18n({
    path: '/',
    component: loadable(() => import(/* webpackChunkName: "body" */ './body')),
    indexRoute: {
      component: loadable(() => import(/* webpackChunkName: "home" */ './page-home')),
      noLowerMenu: true,
      mods: 'pageFill'
    },
    childRoutes: [
      {
        path: 'auth/messages.html',
        component: loadable(() => import(/* webpackChunkName: "messages" */ '../domik/section-profile/page-messages'))
      },
      // *** жилые комплексы
      {
        path: 'akcii',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "old-stocks" */ './section-stocks'))
      },
      // *** О компании
      {
        path: 'o-kompanii',
        mods: 'pageFill',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "about" */ './section-about'))
      },
      // *** Пользовательское соглашение
      {
        path: 'soglashenie',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "terms" */ './section-terms'))
      },
      // *** Политика обработки персональных данных
      {
        path: 'confidence',
        onEnter: slashCheck,
        component: loadable(() => import(/* webpackChunkName: "reg-conds" */ './section-registration-condition'))
      },
      {
        path: 'unsubscribe',
        component: loadable(() => import(/* webpackChunkName: "routes-unsubscribe" */ 'subsys/notifications/page-unsubscribe'))
      },
      // ------------ Favorites -----------------
      {
        path: 'auth/favorites/:project',
        mods: 'pageFill',
        component: loadable(() => import(/* webpackChunkName: "favs" */ './subsys/favorites/'))
      },
      // *** Заказ отчета индекса надежности
      /*
      {
        path: 'order-bild-control.html/:id',
        getComponent: asyncComponent(() =>
          import(/!* webpackChunkName: "bild-control" *!/ './page-order-bild-control'))
      },
      */
      // *** Landings
      {
        path: '*',
        getComponent: landingRoute(landingRoutes)
      }
    ]
  });
}
