/**
 * Created by sem on 12.10.16.
 */

import ApiError from 'errors/apiError';

// const STATUS_SUCCESS = 'success';

export default function apiMiddleware(apiClient) {
  return (/* {dispatch, getState } */) => next => (action) => {
    if (!action.apiRequest) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = action.types;
    const request = action.apiRequest;

    next({ ...action, type: REQUEST });
    const queryString = request.method === 'get' ? request.params : undefined;
    const postData = ['post', 'put', 'delete'].indexOf(request.method) >= 0 ? request.params : undefined;
    apiClient[request.method](request.url, { queryString, postData })
    /* .then((response) => {
     if (response.status !== STATUS_SUCCESS) {
     next({ ...action, type: FAILURE, payload: response });
     } else {
     next({ ...action, type: SUCCESS, payload: response.payload });
     }
     }) */
      .then(payload => next({ ...action, type: SUCCESS, payload }))
      .catch((error) => {
        const { response = {} } = error;
        next({ ...action,
          type: FAILURE,
          payload: {
            httpCode: response.status,
            httpStatusText: response.statusText,
            ...response.data
          } });
        throw new ApiError(error, {
          component: 'Redux API Middleware'
        });
      });
  };
}
