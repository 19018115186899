import { combineReducers } from 'redux';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';

class ReducersRegistry {
  constructor() {
    this._reducers = {};
    this._emitChange = null;
  }

  register(newReducers) {
    if (isObjectsHasSameKeys(newReducers, this._reducers)) {
      return;
    }

    this._reducers = { ...this._reducers, ...newReducers };
    if (this._emitChange != null) {
      this._emitChange(this.getCombinedReducers());
    }
  }

  getCombinedReducers() {
    return combineReducers({ ...this._reducers });
  }

  setChangeListener(listener) {
    if (this._emitChange != null) {
      return;
    }
    this._emitChange = listener;
  }
}

export default ReducersRegistry;

function isObjectsHasSameKeys(Obj1, Obj2) {
  const sameKeys = intersection(keys(Obj1), keys(Obj2));
  return sameKeys.length > 0;
}
