/**
 * Created by DryyMoon on 25.10.2016.
 */
import isFunction from 'lodash/isFunction';
import upperFirst from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import extend from 'lodash/extend';
// import 'raf/polyfill';
// import raf from 'raf';
import isBrowser from '../../helpers/isBrowser';

const eventTypes = ['media', 'resize', 'zoom', 'scroll'];

const IMMEDIATE_EVENTS = eventTypes.map(e => `on${upperFirst(e)}`);
const SYNC_EVENTS = eventTypes.map(e => `on${upperFirst(e)}Sync`);
const START_EVENTS = eventTypes.map(e => `on${upperFirst(e)}Start`);
const END_EVENTS = eventTypes.map(e => `on${upperFirst(e)}End`);
const ALL_EVENTS = [
  ...SYNC_EVENTS,
  ...IMMEDIATE_EVENTS,
  ...START_EVENTS,
  ...END_EVENTS
];

// let instance;

class Sensor {
  constructor() {
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleMedia = this.handleMedia.bind(this);
    this.addListener = this.addListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.emit = this.emit.bind(this);
    this.emitRunner = this.emitRunner.bind(this);
    this.events = {};

    ALL_EVENTS.forEach((e) => {
      this.events[e] = {};
      this.events[e].listeners = [];
      this.events[e].fired = 0;

      this[e] = fn => this.addListener(e, fn);
      this[e.replace(/^on/, 'off')] = fn => this.removeListener(e, fn);
    });
    const that = this;

    SYNC_EVENTS.forEach((e) => {
      this.events[e].fn = () => {
        // that.events[e].raf = null;
        that.emitRunner(e);
      };
    });

    END_EVENTS.forEach((e) => {
      this.events[e].endEventFn = () => {
        that.events[e].fired += 1;
        that.emit();
        that.events[e].awaitFire = false;
      };
      this.events[e].endEventFn = this.events[e].endEventFn.bind(this);
      this.events[e].debounced = debounce(this.events[e].endEventFn, 300);
    });

    this.runtime = { media: {} };
    this.variables = {};

    const init = true;
    this.handleScroll({ init });
    this.handleResize({ init });

    if (isBrowser) {
      window.addEventListener('scroll', this.handleScroll, false);
      window.addEventListener('resize', this.handleResize, false);
    }
  }

  setVariables(vars) {
    this.variables = vars;
  }

  getRuntime() {
    return { ...this.runtime };
  }

  getVariable(name) {
    return this.variables[name] || null;
  }

  addMediaQueries(Obj) {
    if (Obj === null || typeof Obj !== 'object') return false;
    Object.keys(Obj).forEach((key) => {
      const mediaQueryString = Obj[key];

      // Value is Bool, default for server usage.
      if (!!mediaQueryString === mediaQueryString) this.runtime.media[key] = mediaQueryString;

      if (mediaQueryString === null) {
        this.runtime.media[key] = null;
        delete this.runtime.media[key];
      }

      if (isBrowser) {
        this.events.onMedia.store = this.events.onMedia.store || {};
        this.events.onMedia.store[key] = this.events.onMedia.store[key] || {};
        const evStore = this.events.onMedia.store[key];

        // maybe event exist and it is a same
        if (evStore.mediaQueryString === mediaQueryString) return false;

        // maybe listener setted, remove it.
        if (evStore.matchMedia) {
          evStore.matchMedia.removeListener(evStore.handler);
          evStore.matchMedia = null;
        }

        const handler = () => this.handleMedia({ key });

        if (typeof mediaQueryString === 'string') {
          const matchMedia = window.matchMedia(mediaQueryString);
          extend(evStore, { key, mediaQueryString, matchMedia, handler });
          matchMedia.addListener(handler);
        }

        handler();
      }
    });
  }

  addListener(type, fn) {
    if (!isFunction(fn)) return false;
    if (ALL_EVENTS.indexOf(type) === -1) return false;
    if (this.events[type].listeners.indexOf(fn) > 0) return false;
    this.events[type].listeners.push(fn);
  }

  removeListener(type, fn) {
    if (ALL_EVENTS.indexOf(type) === -1) return false;
    const index = this.events[type].listeners.indexOf(fn);
    if (index === -1) return true;
    this.events[type].listeners.splice(index, 1);
    this.removeListener(type, fn);
  }

  handleScroll({ init }) {
    const ev = 'onScroll';

    const { top, left } = getScrollPositionSync();
    this.runtime.scrollTop = top;
    this.runtime.scrollLeft = left;

    if (!init) this.handleEvent(ev);
  }

  handleResize({ init }) {
    const ev = 'onResize';

    this.runtime.scrollBarWidth = getScrollBarWidth();
    const { height, width } = getViewport();
    this.runtime.viewPortHeight = height;
    this.runtime.viewPortWidth = width;

    if (!init) this.handleEvent(ev);
  }

  handleMedia({ key, init }) {
    const ev = 'onMedia';
    this.runtime.media[key] = this.events[ev].store[key].matchMedia.matches;
    if (!init) this.handleEvent(ev);
  }

  emit() {
    if (this.emitting) return;
    this.emitting = true;
    ALL_EVENTS
      .filter((e) => {
        const isNotEmitting = !this.events[e].emitting;
        const isFired = this.events[e].fired > 0;
        return isFired && isNotEmitting;
      })
      .forEach((e) => {
        this.events[e].emitting = true;
        const that = this;
        /*
         * No Improvments with requestAnimationFrame;
         * setTimeout(() => requestAnimationFrame()(() => that.emitRunner(e)));
         */
        setTimeout(() => that.emitRunner(e), 0);
      });
    this.emitting = false;
  }

  emitRunner(e) {
    this.events[e].fired = 0;
    this.events[e].listeners.forEach(fn => fn({ ...this.runtime, event: e }));
    this.events[e].emitting = false;
  }

  handleEvent(ev) {
    // Sync Event
    const syncEvName = `${ev}Sync`;
    this.events[syncEvName].fn();
    // if (this.events[syncEvName].raf) raf.cancel(this.events[syncEvName].raf);
    // this.events[syncEvName].raf = raf(this.events[syncEvName].fn);

    // Start Event
    this.events[ev].fired += 1;
    if (!this.events[`${ev}End`].awaitFire && !this.events[`${ev}End`].emitting) {
      this.events[`${ev}End`].awaitFire = true;
      this.events[`${ev}Start`].fired += 1;
    }

    // End Event
    this.emit();
    this.events[`${ev}End`].debounced();
  }
}

export default Sensor;

const doc = isBrowser ? document.documentElement : {};

function isTouchSupported() { // eslint-disable-line
  if (!isBrowser) return false;
  return 'ontouchstart' in window || navigator.msMaxTouchPoints;
}

export function getScrollPositionSync() {
  if (!isBrowser) return { left: 0, top: 0 };
  const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  return { left, top };
}

function getViewport() {
  if (!isBrowser) return { width: 0, height: 0 };
  // the more standards compliant browsers (mozilla/netscape/opera/IE7)
  // use window.innerWidth and window.innerHeight
  /* if (typeof window.innerWidth !== 'undefined') {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  } */

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !== 'undefined'
    && document.documentElement.clientWidth !== 0) {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    };
  }

  // older versions of IE
  return {
    width: document.getElementsByTagName('body')[0].clientWidth,
    height: document.getElementsByTagName('body')[0].clientHeight
  };
}

const scrollBarWidthContainers = {};

function getScrollBarWidth() {
  if (!isBrowser) return 0;
  scrollBarWidthContainers.inner = scrollBarWidthContainers.inner || document.getElementById('innerContainerOfGetScrollBarWidth');
  scrollBarWidthContainers.outer = scrollBarWidthContainers.outer || document.getElementById('outerContainerOfGetScrollBarWidth');
  let { inner, outer } = scrollBarWidthContainers;
  if (!inner) {
    inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '20rem';
    inner.id = 'innerContainerOfGetScrollBarWidth';
    extend(scrollBarWidthContainers, { inner });
  }
  if (!outer) {
    outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '-30rem';
    outer.style.left = '-30rem';
    outer.style.visibility = 'hidden';
    outer.style.width = '20rem';
    outer.style.height = '15rem';
    outer.style.overflow = 'hidden';
    outer.style.zIndex = '-9999';
    outer.appendChild(inner);
    outer.id = 'outerContainerOfGetScrollBarWidth';
    document.body.appendChild(outer);
    extend(scrollBarWidthContainers, { inner });
  }
  const w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  let w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  // document.body.removeChild(outer);

  return (w1 - w2);
}

export const allEvents = ALL_EVENTS;

// shim layer with setTimeout fallback
/* function requestAnimationFrame() {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function requestAnimationFrameLegacy(callback) {
      window.setTimeout(callback, 1000 / 60);
    };
} */

// this.requestAnimationFrame = requestAnimationFrame();
/*
 (function animloop() {
 requestAnimationFrame()(animloop);
 render();
 })();

 */
