/**
 * Created by sem on 09.06.17.
 */
import update from 'react-addons-update';
import getStore from 'helpers/redux/access-store';
import { subscribe } from 'helpers/global-events';

const LOAD = 'geolocation/LOAD';
const LOAD_SUCCESS = 'geolocation/LOAD_SUCCESS';
const LOAD_FAILURE = 'geolocation/LOAD_FAILURE';

const initialState = {
  action: null,
  loading: false,
  loaded: false,
  failure: false,
  data: {
    status: 'unlocated',
    confirmed: false,
    point: null,
    location: null
  }
};

// i18n re-request
let lastAction = null;
subscribe('gLangChanged', () => {
  if (!lastAction) return;
  const store = getStore();
  store.dispatch(actionGeolocate(...lastAction));
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: true },
        failure: { $set: false },
      });

    case LOAD_SUCCESS:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        loaded: { $set: true },
        failure: { $set: false },
        data: { $set: action.payload }
      });

    case LOAD_FAILURE:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        failure: { $set: true },
      });

    default:
      return state;
  }
}

export function actionGeolocate(confirmed, forceUpdate = false) {
  lastAction = [confirmed, forceUpdate];
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    apiRequest: {
      method: 'get',
      url: '/action/location/common/geolocate',
      params: { confirmed, forceUpdate }
    }
  };
}

