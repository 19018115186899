/**
 * Created by Vit on 14.07.2016.
 */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import values from 'lodash/values';
import isArray from 'lodash/isArray';
import each from 'lodash/each';
import get from 'lodash/get';
import Icon from 'components/icon';

import ButtonState from './button-state';
import { dataViews, btnThemes } from './button-constants';

import 'styles/base/components/button/_button.scss';

const classes = new Bem('btn');
const dataViewType = values(dataViews);
const btnTheme = values(btnThemes);

/**
 * === Button Component ===
 *
 * = Example =
 * <Button
 *   label={text}
 *   iconType={icon}
 *   onClick={func}
 *   dataView={[dataViews.lolo, dataViews.lolo2]}
 *   noPadding
 *   lolo
 * />
 */

export default class Button extends PureComponent {
  static defaultProps = {
    type: 'button'
  };

  static propTypes = {
    children: PropTypes.any, // eslint-disable-line
    className: PropTypes.string,
    style: PropTypes.shape({}),
    type: PropTypes.string,

    // Button title
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),

    // Button text
    label: PropTypes.any, // eslint-disable-line

    // Button secondary text
    labelSecond: PropTypes.any, // eslint-disable-line

    // рендерится тень
    shadow: PropTypes.bool,

    // рендерится заглушка вместо иконки
    insteadIcon: PropTypes.bool,

    // Type of the main Icon (left to the button text)
    iconType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),

    // Type of secondary Icon (right to the button text)
    iconSecondType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),

    // Custom button content for cases with unique or too different buttons
    customRenderer: PropTypes.bool,

    // Different button color themes
    theme: PropTypes.oneOfType([
      PropTypes.oneOf(btnTheme),
      PropTypes.bool
    ]),

    // Button for list (if first-child to add style margin-top, if last-child - add margin-bottom)
    inListDD: PropTypes.bool,

    // Button size
    small: PropTypes.bool,
    big: PropTypes.bool,

    // Button content 'padding: 0'
    noPadding: PropTypes.bool,

    // Button with only Icon
    iconOnly: PropTypes.bool,

    // Button content is Block
    contentBlock: PropTypes.bool,

    // Button content to left
    contentLeft: PropTypes.bool,
    // Button content to right
    contentRight: PropTypes.bool,

    // Additional button view properties, see dataViewType above
    dataView: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(dataViewType),
      PropTypes.arrayOf(PropTypes.oneOf(dataViewType))
    ]),

    // Data-block on the page where button is used
    mainClassProp: PropTypes.string,

    // props for SEO
    // seoProps: PropTypes.shape({}),

    // вместо findDOMNode
    btnRef: PropTypes.func,
    wRef: PropTypes.func,
    active: PropTypes.bool,
  };

  static cache = {};

  static renderLabel(label, mainClass, mods = null, HtmlElement = 'span') {
    let extraClasses = mainClass && `${mainClass}__text`;

    if (mainClass && mods) extraClasses = `${extraClasses || ''} ${mainClass}__text_second`;

    return (
      <HtmlElement {...classes('text', mods, extraClasses)} data-test="textSecondShowButton">{label}</HtmlElement>
    );
  }

  static renderIcon(iconType, mainClass, mods = null, insteadIcon = false) {
    let extraClasses = mainClass && `${mainClass}__icon`;

    if (mainClass && mods) extraClasses = `${extraClasses || ''} ${mainClass}__icon_second`;
    if (insteadIcon) {
      return <span {...classes('icon', 'insteadIcon', extraClasses)} />;
    }

    return <Icon {...classes('icon', mods, extraClasses)} type={iconType} />;
  }

  static renderShadow(mainClass, mods = null) {
    const extraClasses = mainClass && `${mainClass}__btnShadow`;

    return (
      <span {...classes('shadow', mods, extraClasses)} />
    );
  }

  render() {
    const {
      className, style, children, label, labelSecond, iconType, iconSecondType,
      customRenderer, active, theme, shadow, // seoProps = {},
      // olive, red, white, main, tel, sky, light, lightColor,
      inListDD, insteadIcon, small, big, noPadding, iconOnly,
      contentBlock, contentLeft, contentRight,
      dataView, wRef, btnRef, mainClassProp, title, ...rest
    } = this.props;
    const mods = {
      active,
      noPadding,
      iconOnly,
      contentLeft,
      contentRight,
      inListDD,
      small,
      big,
      [theme]: theme
      // olive,
      // red,
      // white,
      // main,
      // tel,
      // sky,
      // light,
      // lightColor
    };
    const HtmlElement = contentBlock ? 'div' : 'span';

    if (dataView) {
      const dataViewIsArray = isArray(dataView);
      if (dataViewIsArray && dataView.length) {
        each(dataView, (view) => {
          if (view) mods[view] = true;
        });
      } else if (!dataViewIsArray) {
        mods[dataView] = true;
      }
    }

    const mainClass = mainClassProp || ButtonState.getMainClassName(this.props.className);

    let content = [];

    if (customRenderer) {
      content = Children.toArray(children)[0];
      if (content) {
        content = cloneElement(content, {
          ...classes('content', null, get(content, 'props.className'))
        });
      }
    } else {
      content = (
        <HtmlElement {...classes('content', null, mainClass && `${mainClass}__btnContent`)} data-test="iconCityButton">
          {!!shadow && Button.renderShadow(mainClass)}
          {!!(iconType || insteadIcon) && Button.renderIcon(iconType, mainClass, null, insteadIcon)}
          {!!label && Button.renderLabel(label, mainClass, null, HtmlElement)}
          {!!labelSecond && Button.renderLabel(labelSecond, mainClass, 'second')}
          {!!iconSecondType && Button.renderIcon(iconSecondType, mainClass, 'second')}
        </HtmlElement>
      );
    }

    return (
      <button
        {...rest}
        {...classes(null, mods, className)}
        title={title || undefined}
        children={content}
        ref={(ref) => {
          if (wRef) wRef(ref);
          if (btnRef) btnRef(ref);
        }}
        // {...seoProps}
        style={style}
      />
    );
  }
}
