/**
 * Created by dryymoon on 29.11.16.
 */
import omit from 'lodash/omit';

const types = {
  UPDATE: 'elementsGeometries/UPDATE',
  DESTROY: 'elementsGeometries/DESTROY'
};

export function destroy(name) {
  return { type: types.DESTROY, name };
}

export function update({ name, coords }) {
  return { type: types.UPDATE, name, coords };
}

export default function reducer(state = {}, { type, name, coords } = {}) {
  switch (type) {
    case types.UPDATE:
      return { ...state, [name]: coords };

    case types.DESTROY:
      return omit(state, [name]);

    default:
      return state;
  }
}

