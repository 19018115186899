/**
 * Created by sem on 09.06.17.
 */
import update from 'react-addons-update';

const LOAD = 'auth/LOAD';
const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
const LOAD_FAILURE = 'auth/LOAD_FAILURE';
export const DO_LOGIN = 'auth/DO_LOGIN';
export const CLEAR_AFTER_LOGIN_TRIGGER = 'auth/CLEAR_AFTER_LOGIN_TRIGGER';

const initialState = {
  action: null,
  loading: false,
  loaded: false,
  failure: false,
  data: {
    authenticated: false
  },
  afterLoginTrigger: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: true },
        failure: { $set: false },
      });

    case LOAD_SUCCESS:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        loaded: { $set: true },
        failure: { $set: false },
        data: { $set: action.payload },
      });

    case LOAD_FAILURE:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        failure: { $set: true },
        afterLoginTrigger: { $set: null }
      });

    case DO_LOGIN: {
      return update(state, {
        action: { $set: action.type },
        afterLoginTrigger: { $set: action.afterLoginTrigger }
      });
    }

    case CLEAR_AFTER_LOGIN_TRIGGER: {
      return update(state, {
        action: { $set: action.type },
        afterLoginTrigger: { $set: null }
      });
    }

    default:
      return state;
  }
}

export function actionDoLogin(afterLoginTrigger = null) {
  return {
    type: DO_LOGIN,
    afterLoginTrigger
  };
}

export function clearAfterLoginTrigger() {
  return {
    type: CLEAR_AFTER_LOGIN_TRIGGER,
    afterLoginTrigger: null
  };
}

export function actionAuthGetData() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    apiRequest: {
      method: 'get',
      url: '/auth/getUserData'
    }
  };
}

export function actionAuthLogout() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    apiRequest: {
      method: 'get',
      url: '/auth/logout'
    },
    afterLoginTrigger: null
  };
}
