import isError from 'lodash/isError';

/**
 * Common error, that can manually calls in application
 */
export default class CommonError extends Error {
  constructor(error, advancedData = {}) {
    super(error);
    if (isError(error)) {
      this.stack = error.stack;
    }
    this.advancedData = advancedData;
    this.name = 'Common Application Error';
  }
}
