import React, { Component } from 'react';
import getDisplayName from 'react-display-name';
import isEqual from 'lodash/isEqual';

import push from './push';

export default function mstatPushDecorator(event, getParams) {
  return function wrapComponent(BaseComponent) {
    return class ComponentMarketStat extends Component {
      static displayName = `mstat(${getDisplayName(BaseComponent)})`;

      constructor(props, ctx) {
        super(props, ctx);
        this.prevParams = {};
        this.prevUrl = null;
      }

      componentDidMount() {
        this.pushStatIfNeeded();
      }

      componentDidUpdate() {
        this.pushStatIfNeeded();
      }

      pushStatIfNeeded() {
        const newParams = getParams(this.props);
        const { location = {} } = this.props; // eslint-disable-line
        const newUrl = location.pathname + location.search;
        if (isEqual(newParams, this.prevParams) && this.prevUrl === newUrl) return;
        if (!newParams) return;
        const { entity, entityId, ...others } = newParams;
        push(event, entity, entityId, others);
        this.prevParams = newParams;
        this.prevUrl = newUrl;
      }

      render() {
        return (
          <BaseComponent {...this.props} />
        );
      }
    };
  };
}
