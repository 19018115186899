/**
 * Created by DryyMoon on 27.10.2016.
 */
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import isFunction from 'lodash/isFunction';
import extend from 'extend';
import SensorLib, { allEvents } from './sensor';

export default function sensor(Component) {
  return class ComponentWitSensor extends Component {
    static displayName = `Sensor(${getDisplayName(Component)})`;

    static contextTypes = extend(Component.contextTypes || {}, {
      sensor: PropTypes.instanceOf(SensorLib)
    });

    constructor(props, context) {
      super(props, context);
      this.sensor = context.sensor;
    }

    componentDidMount() {
      allEvents.forEach((e) => {
        if (this[e] && isFunction(this[e])) {
          this[e] = this[e].bind(this);
          this.context.sensor[e](this[e]);
        }
      });
      if (super.componentDidMount) super.componentDidMount();
    }

    componentWillUnmount() {
      allEvents.forEach((e) => {
        if (this[e] && isFunction(this[e])) {
          this.context.sensor[e.replace(/^on/, 'off')](this[e]);
        }
      });
      if (super.componentWillUnmount) super.componentWillUnmount();
    }
  };
}

