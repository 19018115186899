/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { serverSubscribe, serverUnsubscribe } from 'helpers/server-events';
import get from 'lodash/get';
import { actionAuthGetData } from 'auth/redux';
import { actionGetNotifyData } from 'subsys/notifications/redux';

/**
 * Subscribes to global server/client events for
 * update redux data if needed
 * Also - do initial calls for initialize redux store from server
 */
@connect(state => ({
  auth: state.auth,
  notification: state.notification
}), dispatch => ({
  actionAuthGetData: bindActionCreators(actionAuthGetData, dispatch),
  actionNotifyGetData: bindActionCreators(actionGetNotifyData, dispatch),
})
)
export default class ReduxUpdateStore extends PureComponent {
  constructor(props, ctx) {
    super(props, ctx);
    this.subUserId = null;
    this.subscribeForUserEventsIfNeeded(props);
  }

  componentDidMount() {
    this.props.actionAuthGetData();
  }

  componentDidUpdate(prevProps) {
    if (get(this.props, 'auth.data.id', null) !== get(prevProps, 'auth.data.id', null)) {
      this.props.actionNotifyGetData();
      this.subscribeForUserEventsIfNeeded(this.props);
    }
  }

  componentWillUnmount() {
    if (this.subUserId) {
      serverUnsubscribe(
        'App\\Events\\User\\Updated',
        this.subUserId,
        this.props.actionAuthGetData
      );
      serverUnsubscribe(
        'App\\Events\\User\\MessageReceived',
        this.subUserId,
        this.props.actionNotifyGetData
      );
    }
  }

  subscribeForUserEventsIfNeeded(props) {
    if (this.subUserId) {
      serverUnsubscribe(
        'App\\Events\\User\\Updated',
        this.subUserId,
        props.actionAuthGetData
      );
      serverUnsubscribe(
        'App\\Events\\User\\MessageReceived',
        this.subUserId,
        this.props.actionNotifyGetData
      );
      this.subUserId = null;
    }

    if (props.auth.data && props.auth.data.id) {
      if (__DEV_CLI__) {
        // eslint-disable-next-line
        console.info('Global redux subscribe for user updates');
      }
      this.subUserId = props.auth.data.id;
      serverSubscribe(
        'App\\Events\\User\\Updated',
        this.subUserId,
        props.actionAuthGetData
      );
      serverSubscribe(
        'App\\Events\\User\\MessageReceived',
        this.subUserId,
        this.props.actionNotifyGetData
      );
    }
  }


  render() {
    return null;
  }
}
