import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';

import Button from './button';

/**
 * === ButtonState Component ===
 * Uses by props when button with own state is needed
 *
 * = Example =
 * <ButtonState
 *   labelMain={main text}
 *   labelActive={active text}
 *   iconTypeMain={main icon}
 *   iconTypeActive={active icon}
 *   onClick={func}
 * >
 */
export default class ButtonState extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Data-mods for button. If undefined uses iconType or iconTypeMain as data-mods
    iconMods: PropTypes.string,

    // Button title when state change shouldn't affect title change
    title: PropTypes.string,

    // Button title before state is changed
    titleMain: PropTypes.string,

    // Button title after state is changed
    titleActive: PropTypes.string,

    // Button label when state change is not expected
    label: PropTypes.any, // eslint-disable-line

    // Button label when state change shouldn't affect label change
    labelMain: PropTypes.any, // eslint-disable-line

    // Button label after state is changed
    labelActive: PropTypes.any, // eslint-disable-line

    // Primary icon when state change shouldn't affect icon change
    iconType: PropTypes.string,

    // Primary icon before state is changed
    iconTypeMain: PropTypes.string,

    // Primary icon after state is changed
    iconTypeActive: PropTypes.string,

    // Secondary icon when state change shouldn't affect icon change
    iconSecondType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),

    // Secondary icon before state is changed
    iconSecondTypeMain: PropTypes.string,

    // Secondary icon after state is changed
    iconSecondTypeActive: PropTypes.string,

    onClick: PropTypes.func,

    // вместо findDOMNode
    wRef: PropTypes.func
  };

  static getMainClassName(className = null) {
    if (!className) return '';
    return className.replace(/_+/g, ' ').split(' ')[0];
  }

  constructor(props) {
    super(props);
    this.state = { active: false };

    this.onClickChange = this.onClickChange.bind(this);
  }

  onClickChange(...args) {
    if (this.props.onClick) this.props.onClick(...args);
    this.setState(
      prevState => ({ active: !prevState.active })
    );
  }

  render() {
    const {
      className, iconMods, wRef,
      titleMain, titleActive,
      labelMain, labelActive,
      iconTypeMain, iconTypeActive,
      iconSecondTypeMain, iconSecondTypeActive,
      ...restProps
    } = this.props;
    const mainClassProp = ButtonState.getMainClassName(this.props.className);
    const { active } = this.state;
    const typeOfIcon = this.props.iconType || (active ? iconTypeActive : iconTypeMain);
    const typeOfSecondIcon = this.props.iconSecondType ||
      (active ? iconSecondTypeActive : iconSecondTypeMain);
    const mods = camelCase(iconMods || this.props.iconType || iconTypeMain);
    let buttonClassName = mainClassProp && `${className} ${mainClassProp}__btn_${mods}`;
    if (active) buttonClassName = `${buttonClassName} ${mainClassProp}__btn_${active ? 'active' : ''}`;

    const props = {
      ...restProps,
      active: this.state.active,
      onClick: this.onClickChange,
      className: buttonClassName || className,
      title: this.props.title || (active ? titleActive : titleMain),
      label: this.props.label || (active ? labelActive : labelMain),
      iconType: typeOfIcon,
      iconSecondType: typeOfSecondIcon,
      mainClassProp
    };
    if (wRef) props.ref = wRef;

    return <Button {...props} />;
  }
}
