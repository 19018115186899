/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import md5 from 'md5';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import map from 'lodash/map';
import PreloaderError from 'errors/preloaderError';


import apiDecorator from './api-decorator';

import NotFoundContent from 'containers/not-found/not-found-content';

let ssrPreloadPathHash = null;
let ssrPreloadData = null;

/**
 * = Preload Decorator =
 *
 * == Usage ==
 * @preload(propsResolversConfig)
 *
 * == Example ==
 * @preload({
 *   // simple resolver
 *   // key - prop name, value - array
 *   //   first element - promise generator (function, which must return a promise)
 *   //   optional second argument - parameters for promise generator
 *   someDict: [Dict.preloader, {url: 'Dict/Some'}],
 *   someData: [DataProvider.preloader, {url: 'Domik/Some/Provider', query: {trololo: 10}}],
 *
 *   // resolver, which will wait another prop
 *   someBlocksData: {
 *     // props to wait, must be an array
 *     $wait: ['someDict'],
 *
 *     // callback for config generation. Must return config, same as main
 *     // data - received data in format data.someDict={}
 *     // routeProps - standart react router route props
 *     $callback: (data, routeProps, response) => {
 *        let cfg = {};
 *        each(data.someDict, (val) => cfg[val.id] = [DataProvider.preloader, {id: val.id}] );
 *        return cfg;
 *     }
 *   },
 *
 *   // Injecting request/route params (see below)
 *   someOtherData: [DataProvider.preloader, {region: '$route.query.region'}]
 * })
 *
 * == Callbacks in params ==
 * Preloader param can be a function, such as:
 * query: (routeProps) => { do something }
 *
 * == Injecting request or route params ==
 * Possible params are:
 *   $route.params.{key} - React route params (placehoder values), props.params
 *   $route.query.{key} - React route query string params, props.location.query
 *   $route.path - React route path
 *
 *  == Loader Props ==
 *  noReload - do not reload data when url changed. Needed for "body" shared component
 */

export default function factory(preloadConfig, loaderProps = {}) {
  return (BaseComponent) => {
    //if (!isFunction(BaseComponent)) {
    //  throw new PreloaderError('data-preload factory must decorate only React Component');
    //}

    @apiDecorator
    class PreloadComponent extends Component {
      static displayName = `dataPreload(${getDisplayName(BaseComponent)})`;
      static preloadConfig = preloadConfig;
      static loaderProps = loaderProps;

      static contextTypes = {
        ...BaseComponent.contextTypes,
      };

      static childContextTypes = {
        preloadRunning: PropTypes.bool
      };

      constructor(props, context) {
        super(props, context);
        // ssrPreloaded - only for Server Side
        this.isPreloaded = props.ssrPreloaded ? true : false;
        this.mounted=true;

        const urlHash = buildUrlHash(props);
        const genConfig = typeof PreloadComponent.preloadConfig === 'function' ?
          PreloadComponent.preloadConfig(props) : PreloadComponent.preloadConfig;
        let urlHashSet = null;

        let existsData = this.props.ssrPreloaded || null;

        // If data already preloaded
        if (ssrPreloadPathHash !== null && ssrPreloadPathHash === urlHash) {
          existsData = {};
          map(genConfig, (val, key) => {
            existsData[key] = ssrPreloadData[key];
            delete ssrPreloadData[key];
          });

          if (isEmpty(ssrPreloadData)) {
            ssrPreloadPathHash = null;
            ssrPreloadData = null;
          }
          this.isPreloaded = false;
          urlHashSet = urlHash;
        }

        this.state = {
          loading: false,
          loaded: existsData ? true : false,
          error: false,
          data: existsData || null,
          urlHash: urlHashSet
        };
      }

      getChildContext() {
        return { preloadRunning: this.state.loading };
      }

      loadDataIfNeeded(props) {
        if (__SERVER__) {
          return;
        }

        const urlHash = buildUrlHash(props);
        if (PreloadComponent.loaderProps.noReload && this.state.data) {
          this.setState({ urlHash });
          return;
        }

        const genConfig = typeof PreloadComponent.preloadConfig === 'function' ?
          PreloadComponent.preloadConfig(props) : PreloadComponent.preloadConfig;

        // If we need to load data
        if (
          !this.state.loading &&
          !this.isPreloaded &&
          this.state.urlHash !== urlHash) {

          const loadersSharedConfig = {
            api: this.api
          };
          // Workaround for bypass troubles with use context in new react lifecycle methods
          each(this.state.data, (val) => Object.defineProperty(val, '$running', { value: true, enumerable: false }));
          this.setState({
            error: false,
            loading: true
            }, () =>
            runPreload(genConfig, loadersSharedConfig, props)
              .then((data) => {
                if (this.mounted) {
                  this.setState({
                    loading: false,
                    loaded: true,
                    error: false,
                    data,
                    urlHash
                  });
                }
              })
              .catch((error) => {
                if (this.mounted) {
                  this.setState({
                    loading: false,
                    loaded: true,
                    error: true,
                    data: null,
                    urlHash
                  }, () => {
                    throw new PreloaderError(error);
                  });
                } else {
                  throw new PreloaderError(error);
                }
              })
          );
        }
      }

      componentDidMount() {
        this.loadDataIfNeeded(this.props);
      }

      componentDidUpdate() {
        this.loadDataIfNeeded(this.props);
      }

      shouldComponentUpdate(nextProps, nextState) {
        return (
          this.state.urlHash != buildUrlHash(nextProps) ||
          nextState.urlHash != buildUrlHash(nextProps) ||
          this.state.loaded != nextState.loaded
        );
      }

      componentWillUnmount() {
        this.mounted=false;
      }

      render() {
        if (!this.state.data) return null;

        const checked = this.state.data.$check || { status: {} };
        if (checked.status.error)  return (<NotFoundContent />);

        if (!this.state.loaded) return null;
        const { ssrPreloaded, ...props} = this.props;
        return (<BaseComponent { ...props } { ...this.state.data } />);
      }
    }

    class PreloadComponentWrp extends Component {
      static displayName = `dataPreload(${getDisplayName(BaseComponent)})`;
      static preloadConfig = preloadConfig;
      static loaderProps = loaderProps;

      static contextTypes = {
        ...BaseComponent.contextTypes,
      };

      render() {
        if (this.props.$doNotRunPreload) {
          return <BaseComponent {...this.props} />;
        }
        return <PreloadComponent {...this.props} />;
      }
    }

    return PreloadComponentWrp;
  };
}

/**
 * Resolve 'wait' props
 * @param promises
 * @param wait
 * @param callback
 * @return {Function}
 */
function resolveWait(promises, routeProps, loadersSharedConfig, wait, callback) {
  return function() {
    return new Promise(function(accept, reject) {
      // We need promises with all promises running
      setTimeout(() => {
        let queueRunning = 0;
        let allData = {};
        each(wait, (val) => {
          queueRunning++;
          try {
            promises[val].then((data) => {
              queueRunning--;
              allData[val] = data;
              if (queueRunning == 0) {
                let newConfig = callback(allData, routeProps);
                runPreload(newConfig, loadersSharedConfig, routeProps).then((resolvedData) => {
                  accept(resolvedData);
                }).catch((error) => reject(error))
              }
            }).catch((error) => reject(error));
          } catch(error) {
            throw new PreloaderError(error);
          }
        });
      }, 0);
    });
  }
}


/**
 * Resolve preloads
 * @param config
 */
function runPreload(config, loadersSharedConfig, routeProps) {
  return new Promise((accept, reject) => {
    let queueRunning = 0;
    let allData = {};
    let promises = {};
    let resolverParams = {};

    // Map wait resolvers and promise function to promises object
    each(config, (val, key) => {
      if (!allData[key]) {
        if (isObject(val) && val.$wait) {
          if (!isArray(val.$wait)) {
            throw new PreloaderError('$wait in preload must be an array');
          }
          promises[key] = resolveWait(promises, routeProps, loadersSharedConfig, val.$wait, val.$callback);
        } else if (isArray(val)) {
          const [resolver, params = {}] = val;
          resolverParams[key] = parseResolverParams(params, routeProps, loadersSharedConfig);
          promises[key] = resolver;
        }
      }
    });

    // Run promises
    each(promises, (val, key) => {
      if (isFunction(val)) {
        queueRunning++;
        promises[key] = val(resolverParams[key]);
        promises[key].then((data) => {
          if (typeof data === 'undefined') {
            throw new PreloaderError(
              `Data Preloader with key ${key} returns undefined! It maybe happens, `+
              'because server returned an encrypted data');
          }
          queueRunning--;
          allData[key] = data;
          Object.defineProperty(
            allData[key],
            '$loaded', {
              value: true,
              enumerable: false
            });
          if (queueRunning==0) {
            accept(allData);
          }
        }).catch((error) => {
          reject(error);
        });
      }
    });

    // Empty queue? Oh, shi...
    if (queueRunning==0) {
      accept(allData);
    }
  });
}

function buildUrlHash(props) {
  const { location: { pathname, search, query } } = props;
  return md5(JSON.stringify({ pathname, search, query }));
}

function parseResolverParams(params, routeProps, loadersSharedConfig) {
  let parsedParams = {...loadersSharedConfig};
  const path = routeProps.location.pathname.replace(/\/page-\d+/, '');
  each(params, (val, key) => {
    if (typeof val === 'function') {
      parsedParams[key] = val(routeProps);
    } else if (!isArray(val) && isObject(val)) {
      parsedParams[key] = parseResolverParams(val, routeProps);
    } else if (isString(val)) {
      const q = val.split('.');
      if (q && q[0] === '$route') {
        switch(q[1]) {
          case 'params':
            parsedParams[key] = routeProps.params[q[2]];
            break;
          case 'query':
            parsedParams[key] = routeProps.location.query[q[2]];
            break;
          case 'path':
            parsedParams[key] = path;
            break;
          default:
            parsedParams[key] = val;
            break;
        }
      } else {
        parsedParams[key] = val;
      }
    } else {
      parsedParams[key] = val;
    }
  });
  return parsedParams;
}


export function preloadExecutor(renderProps, loadersSharedConfig, ssrPreload = {}) {
  /**
   * If on server - return a promise, because we should wait a data for initial render
   */
  if (__SERVER__) {
    const route = renderProps.routes[renderProps.routes.length-1];

    // Find preload config in components
    const getCfgPromises = [];
    map(renderProps.components, (component) => {
      if (!component) {
        return;
      }

      // *** Standard (old) "sync" config get
      if (component.preloadConfig) {
        getCfgPromises.push(Promise.resolve(component));
      }
      // *** React-Loadable adapter - async get of config
      //if (typeof component.preload === 'function') {
      //  getCfgPromises.push(component.preload().then((mod) => mod.default));
      //}
      // *** Loadable components adapter - async get of config
      if (typeof component.load === 'function') {
        getCfgPromises.push(component.load().then((mod) => mod.default));
      }
    });

    return new Promise((accept, reject) => {
      Promise.all(getCfgPromises).then((vals) => {
        let allConfigs = {};
        map(vals, (cfg) => {
          if (cfg.preloadConfig) {
            if (typeof cfg.preloadConfig === 'function') {
              allConfigs = {
                ...allConfigs,
                ...cfg.preloadConfig({ route, ...renderProps })
              };
            } else {
              allConfigs = {
                ...allConfigs,
                ...cfg.preloadConfig
              };
            }
          }
        });
        runPreload(allConfigs, loadersSharedConfig, renderProps)
          .then((...props) => accept(...props))
          .catch((...props) => reject(...props));
      });
    });
    // return runPreload(foundConfig, loadersSharedConfig, renderProps);
  }

  /**
   * If on client - define a preloaded from server data and route path
   * runPreload will be call in decorator
   */
  if (__CLIENT__ && ssrPreload !== null) {
    ssrPreloadData = ssrPreload;
    ssrPreloadPathHash = buildUrlHash(renderProps);
  }
  return Promise.resolve();
}
