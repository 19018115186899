/**
 * Created by PIV on 29.06.2016.
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import extend from 'extend';

class Provider extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static childContextTypes = {
    getUid: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.getUid = this.getUid.bind(this);
    this._currentUid = 0;
  }

  getChildContext() {
    return { getUid: this.getUid };
  }

  getUid() {
    this._currentUid += 1;
    return this._currentUid;
  }

  render() {
    return this.props.children;
  }
}
export { Provider };

function factory() {
  return function wrapComponent(BaseComponent) {
    return class ComponentWithUniqId extends BaseComponent {
      static displayName = `uniqId(${getDisplayName(BaseComponent)})`;

      static contextTypes = extend(BaseComponent.contextTypes || {}, {
        getUid: PropTypes.func
      });

      constructor(props, context) {
        super(props, context);
        this.getUid = context.getUid;
        this.uid = context.getUid();
      }
    };
  };
}
export default factory();
export { factory };
