import { Component, Children } from 'react';
import PropTypes from 'prop-types';
import ApiClient from '../../api/api-client';

class ReactApiProvider extends Component {
  static propTypes = {
    api: PropTypes.instanceOf(ApiClient).isRequired,
    children: PropTypes.node
  }

  static childContextTypes = {
    api: PropTypes.instanceOf(ApiClient)
  }

  getChildContext() {
    return { api: this.props.api };
  }

  render() {
    return Children.only(this.props.children);
  }
}

export default ReactApiProvider;
