import PropTypes from 'prop-types';

import isArray from 'lodash/isArray';
import each from 'lodash/each';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { subscribe, unsubscribe } from 'helpers/global-events';
import DataLoader from './data-loader';

function modifyUrlPrefix(props) {
  if (props && props.cache) {
    return `data-cache/${props.cache}/`;
  }
  return 'data/';
}

/**
 * Implements data provider functionality
 *
 */
export default class DataProvider extends DataLoader {
  static propTypes = {
    ...DataLoader.propTypes,
    /** If true - return only first object instead of array * */
    single: PropTypes.bool,
    className: PropTypes.any, // eslint-disable-line
    tagSpan: PropTypes.bool,
    // Global Event, that calls provider reloading
    updateEvent: PropTypes.string,
    // Update only if data contains record with
    // match corresponding column
    updateMatch: PropTypes.string,
    updateMatchDataField: PropTypes.string
  };

  static defaultProps = {
    ...DataLoader.defaultProps,
    method: 'get',
    urlPrefix: modifyUrlPrefix,
    injectPropName: 'data',
    renderMethod: 'single',
    single: false,
    tagSpan: false,
  };

  static preload(params = {}) {
    return DataLoader._preload(DataProvider.defaultProps, params).then((payload) => {
      if (payload.$error) {
        return {
          data: [],
          count: 0,
          countMethod: 'strict',
          error: payload.$error
        };
      }
      return payload;
    });
  }

  constructor(props, ctx) {
    super(props, ctx);
    this._handleUpdateEvent = this._handleUpdateEvent.bind(this);

    if (!__SERVER__ && props.updateEvent) {
      subscribe(props.updateEvent, this._handleUpdateEvent);
    }
  }

  _handleUpdateEvent(evtData) {
    const { updateMatch, updateMatchDataField } = this.props;
    if (updateMatch && evtData.$action !== 'insert') {
      let doUpdate = false;
      each(this.data, (item) => {
        const field = updateMatchDataField ?
          item[updateMatchDataField] : item;
        // eslint-disable-next-line
        if (field[updateMatch] == evtData[updateMatch]) {
          doUpdate = true;
        }
      });
      if (doUpdate) {
        this.reload();
      }
      return null;
    }
    this.reload();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.props.updateEvent) {
      unsubscribe(this.props.updateEvent, this._handleUpdateEvent);
    }
  }

  getMetadata(data) {
    const metadata = {};
    if (data && isNumber(data.count) && data.countMethod) {
      metadata[`${this.props.injectPropName}Count`] = data.count;
      metadata[`${this.props.injectPropName}CountMethod`] = data.countMethod;
    }
    return metadata;
  }

  transformData(providerData = {}) {
    if (this.props.single) {
      if (isArray(get(providerData, 'data'))) {
        return super.transformData(get(providerData, 'data[0]') || {});
      }
      return super.transformData(providerData || {});
    }
    return super.transformData(get(providerData, 'data')) || [];
  }
}
