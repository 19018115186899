/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */

import { useRouterHistory } from 'react-router';
import { createHistory } from 'history';
import axios from 'axios';
import { performRedirect } from 'api/redirect';
import each from 'lodash/each';
import map from 'lodash/map';
import { langs, langIsoMapBack } from 'helpers/i18n';

import config from '../../../config';

const apiUrl = __SERVER__ ? config.apiUrl : config.apiUrlFront;
const landingUrl = `${apiUrl}/landing`;

/**
 * Regexp routes helper
 * Usage:
 * ```
 *   path: '/path/:url', // Route. Param MUST be named as ":url"!
 *   getComponent: regexpRoute([
 *     {
 *     match: /(\S+)-(\d+)\.html/, // Regexp which will be match
 *     component: () => import('./my/route/handler'), // MUST BE A FUNCTION,
 *                                                       which returns async import
 *     params: ['url', 'id'] // Param naming: first, second, third etc. matches:
 *                              (\s+) => 'url' (\d+) => 'id' etc.
 *                              This params injects to standart routeProps
 *     }
 *   ])
 * ```
 *
 * Iterate config, and process first matching regexp.
 *
 * @param array config
 */
export function regexpRoute(cfg) {
  return (router) => {
    let found = null;
    each(cfg, (val) => {
      if (!found) {
        found = processRoute(val, router);
      }
    });
    if (!found) {
      return import('containers/not-found').then(mod => mod.default);
    }
    return found;
  };
}

export function indexRoute(mod, otherProps) {
  return {
    component: mod.default,
    ...otherProps,
    onEnter: (nextState, replace) => {
      const path = nextState.location.pathname;
      if (path.substr(-1, 1) !== '/') {
        if (__SERVER__) {
          replace(`${path}/`);
        }
        if (__CLIENT__) {
          const history = useRouterHistory(createHistory)();
          history.replace(`${path}/`);
        }
      }
    }
  };
}

/**
 * Helper for async importing components
 * @param function imp - function, which returns async import clause
 */
export function asyncComponent(imp) {
  return () => imp().then(mod => mod.default);
}

/**
 * Loader/Mapper for async child routes, that adds i18n information to child routes
 * Method applies automatically, when getChildRoutes: asyncComponent(...) is used for
 * child routes async get
 * @param langInfo
 * @param imp
 * @return {function(): *}
 */
function asyncRoutesI18n(langInfo, imp) {
  return () => imp().then((mod) => {
    return {
      childRoutes: map(mod.childRoutes, (val) => {
        const rv = {
          ...val,
          lang: langInfo.lang,
          defLang: `${langInfo.defLang}/${val.path}`
        };
        rv.langs = {};
        each(langInfo.langs, (lVal, lKey) => {
          rv.langs[lKey] = `${lVal}/${val.path}`;
        });
        if (rv.getChildRoutes) {
          const nImp = rv.getChildRoutes;
          rv.getChildRoutes = asyncRoutesI18n({
            lang: langInfo.lang,
            langs: rv.langs,
            defLang: rv.defLang
          }, nImp);
        }
        return rv;
      })
    };
  });
}

function processRoute(cfg, router) {
  let { hash = '' } = router.location;
  let { url, splat = '' } = router.params;
  if (url.indexOf('#') > 0) {
    const parsed = url.split('#');
    url = parsed[0];
    hash = `#${parsed[1]}`;
  } else if (splat.indexOf('#') >= 0) {
    const parsed = splat.split('#');
    splat = parsed[0];
    hash = `#${parsed[1]}`;
  }
  const fullUrl = url + splat;
  const parsed = fullUrl.match(cfg.match);

  if (parsed) {
    // Re-define route params
    for (let i = 1; i < parsed.length; i += 1) {
      // eslint-disable-next-line
      router.params[cfg.params[i - 1]] = parsed[i];
    }
    // Write hash prop into route params
    if (hash && hash.length) {
      const parsedHash = hash.replace('#', '').split('-');
      // eslint-disable-next-line
      router.params[parsedHash[0] || 'hash'] = parsedHash[1];
    }
    return cfg.component().then(module => module.default);
  }
  return null;
}

function landing404(routeProps, accept) {
  // Send HTTP Code 404 if landing not found
  // Only on SSR needed
  routeProps.httpStatus = 404; //eslint-disable-line
  routeProps.routes.push({
    lang: 'ru',
    defLang: 'ru'
  });

  import('containers/not-found')
    .then(module => accept(module.default));
}

function landing410(routeProps, accept) {
  routeProps.httpStatus = 410; //eslint-disable-line
  import('containers/not-found')
    .then(module => accept(module.default));
}

/**
 * Uses for landing pages (fully custom routes, which config stored on server)
 * @param config
 */
export function landingRoute(landingConfig, params = {}) {
  const project = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
  return routeProps => new Promise((accept, reject) => { // eslint-disable-line
    const pageFromUrl = routeProps.location.pathname.match(/^(.*)\/(page-(\d+))$/);
    const pageWSlash = routeProps.location.pathname.match(/^(.*\/page-\d+)\/$/);
    const url = pageFromUrl ? pageFromUrl[1] : routeProps.location.pathname;

    if (pageWSlash) {
      return performRedirect({ to_url: pageWSlash[1] });
    }

    const reqParams = {
      project,
      url
    };
    if (params.force) {
      reqParams.force = 1;
    }

    axios.get(landingUrl, {
      params: reqParams
    }).then((response) => {
      const landing = response.data.payload;

      if (landing.redirect) {
        return performRedirect({ ...landing.redirect, query: routeProps.location.search });
      }

      // *** Check for 404's
      // Non-exists landing
      if (!landing || !landing.id) {
        return landing404(routeProps, accept);
      }

      const conf = landingConfig[landing.component];

      // If non-exists landing configuration
      if (!conf) {
        return landing404(routeProps, accept);
      }

      // If page was sent but pagination is undefined
      if (pageFromUrl && !conf.pagesAccept) {
        return landing404(routeProps, accept);
      }

      // If page was DELETED
      if (landing && landing.deleted) {
        return landing410(routeProps, accept);
      }

      // *** Update route configuration and get landing component
      // eslint-disable-next-line
      routeProps.params = {
        landing,
        url,
        page: pageFromUrl ? parseInt(pageFromUrl[3], 10) : null,
        ...routeProps.params,
        ...conf,
      };

      routeProps.routes.push({
        path: url,
        ...conf,
        lang: landing.lang,
        langs: landing.langs,
        defLang: landing.xDefaultLang
      });

      if (conf.getComponent) {
        conf.getComponent()
          .then((module) => {
            accept(module.default);
          });
      }

      if (conf.component) {
        accept(conf.component);
      }

    }).catch((err) => {
      if (err && err.response && err.response.status === 404) {
        return landing404(routeProps, accept);
      }
      reject(err);
    });
  });
}

export function i18n(routes) {
  const newRoutes = [];
  const indexPaths = { ru: '/' };
  each(langs, (langName, lang) => {
    indexPaths[lang] = `/${langIsoMapBack[lang]}`;
    newRoutes.push({
      ...routes.indexRoute,
      onEnter: slashCheck,
      path: `${langIsoMapBack[lang]}`,
      lang,
      langs: indexPaths,
      defLang: '/'
    });
  });
  each(routes.childRoutes, (route) => {
    if (route.path !== '*') {
      const pageLangs = {};
      const defLang = route.path;
      pageLangs[config.locale] = `/${route.path}`;
      each(langs, (langName, lang) => pageLangs[lang] = `/${langIsoMapBack[lang]}/${route.path}`);
      newRoutes.push({
        ...route,
        lang: config.locale,
        langs: pageLangs,
        defLang: `/${defLang}`
      });
      each(langs, (langName, lang) => newRoutes.push({
        ...route,
        path: `${langIsoMapBack[lang]}/${route.path}`,
        lang,
        langs: pageLangs,
        defLang: `/${defLang}`
      }));
    } else {
      newRoutes.push(route);
    }
  });

  // getChildRoutes fix
  each(newRoutes, (val, key) => {
    if (val.getChildRoutes) {
      const imp = val.getChildRoutes;
      newRoutes[key].getChildRoutes = asyncRoutesI18n({
        lang: val.lang,
        langs: val.langs,
        defLang: val.defLang
      }, imp);
    }
  });

  const rv = {
    ...routes,
    indexRoute: {
      ...routes.indexRoute,
      lang: config.locale,
      langs: indexPaths,
      defLang: '/'
    },
    childRoutes: newRoutes
  };
  return rv;
}

export function slashCheck(route, redirect) {
  const { location } = route;
  const { pathname } = location;
  const isSlash = pathname.match(/(.*)\/$/);
  if (pathname !== '/' && isSlash) {
    redirect(isSlash[1]);
  }
  return null;
}
