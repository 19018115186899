/* eslint-disable */
import Notification from 'components/notification';
import ProjectConfig from '../../config';

import isObject from 'lodash/isObject';

export function getErrorMessage(err) {
  if (isObject(err)) {
    const { response = {} } = err;
    const { data = {} } = response;
    const { error = {} } = data;
    const { message = null } = error;
    if (message) {
      return message;
    }
    if (err.message) {
      return err.message;
    }
    if (err.error && err.error.message) {
      return err.error.message;
    }
  }
  return err;
}

export function reportError(err) {
  const msg = getErrorMessage(err);
  Notification.error(msg);
}
