/**
 * Created by PIV on 22.06.2016.
 */
import { getScrollPositionSync } from './sensor';
import sensor from './decorator';
import provider from './provider';

export default sensor;
// export const SensorModule = module;
export const SensorProvider = provider;
export { getScrollPositionSync };
