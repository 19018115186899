const iconTypes = {
  arrowUp: 'arrowUp',
  // arrowDown: 'arrowDown',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  arrowTopMost: 'arrowTopMost',
  converter: 'converter',
  lock: 'lock',
  anglesMultiCheck: 'anglesMultiCheck',
  checkCircle: 'checkCircle',
  checkCircleBild: 'checkCircleBild',
  tag: 'tag',
  taxi: 'taxi',
  search: 'search',
  searchBild: 'searchBild',
  save: 'save',
  formatBold: 'formatBold',
  formatItalic: 'formatItalic',
  formatUnderline: 'formatUnderline',
  formatStrike: 'formatStrike',
  quote: 'quote',
  formatQuote: 'formatQuote',
  arrowSliderLeft: 'arrowSliderLeft',
  arrowSliderRight: 'arrowSliderRight',
  settings: 'settings',
  affix: 'affix',
  carret: 'carret',
  carretRight: 'carretRight',
  carretBild: 'carretBild',
  receipt: 'receipt',
  cart: 'cart',
  dropdown: 'dropdown',
  moneyLong: 'moneyLong',
  moneyBank: 'moneyBank',
  installment: 'installment',
  logoV: 'logoV',
  logoDomik: 'logoDomik',
  logoForum: 'logoForum',
  logoDomikInForum: 'logoDomikInForum',
  formatUnorderedList: 'formatUnorderedList',
  formatOrderedList: 'formatOrderedList',
  logoDotsWhite: 'logoDotsWhite',
  logoBild: 'logoBild',
  logoBildPotato: 'logoBildPotato',
  logoBildSquareWhite: 'logoBildSquareWhite',
  logoBildControl: 'logoBildControl',
  logoBildControlText: 'logoBildControlText',
  logoA2: 'logoA2',
  favoritesBorder: 'favoritesBorder',
  newTab: 'newTab',
  built: 'built',
  power: 'power',
  sortVert: 'sortVert',
  send: 'send',
  logIn: 'logIn',
  logOut: 'logOut',
  menu: 'menu',
  menuDomik: 'menuDomik',
  favorites: 'favorites',
  bed: 'bed',
  person: 'person',
  persons: 'persons',
  personCircle: 'personCircle',
  personInSquare: 'personInSquare',
  dns: 'dns',
  laptop: 'laptop',
  laptopMonitor: 'laptopMonitor',
  notification: 'notification',
  notificationNone: 'notificationNone',
  pages: 'pages',
  share: 'share',
  moreLabel: 'moreLabel',
  build: 'build',
  builds: 'builds',
  // buildsFilled: 'buildsFilled',
  overflight: 'overflight',
  developers: 'developers',
  // developersFilled: 'developersFilled',
  // checkAll: 'checkAll',
  questionAnswer: 'questionAnswer',
  discussion: 'discussion',
  calendar: 'calendar',
  clock: 'clock',
  checkbox: 'checkbox',
  checkboxChecked: 'checkboxChecked',
  checkboxCheckedAll: 'checkboxCheckedAll',
  reply: 'reply',
  votingUp: 'votingUp',
  votingDown: 'votingDown',
  radiobox: 'radiobox',
  radioboxChecked: 'radioboxChecked',
  timelapse: 'timelapse',
  trendingUp: 'trendingUp',
  screen: 'screen',
  fullscreen: 'fullscreen',
  gift: 'gift',
  // giftFilled: 'giftFilled',
  finflash: 'finflash',
  marker: 'marker',
  markerBorder: 'markerBorder',
  markerBorderLighter: 'markerBorderLighter',
  // markerFilled: 'markerFilled',
  refresh: 'refresh',
  filter: 'filter',
  imageUndefined: 'imageUndefined',
  announcement: 'announcement',
  information: 'information',
  infoCircleBorder: 'infoCircleBorder',
  codeSettings: 'codeSettings',
  // percent: 'percent',
  ratingIN: 'ratingIN',
  bc: 'bc',
  folder: 'folder',
  folderOpen: 'folderOpen',
  club: 'club',
  flag: 'flag',
  crown: 'crown',
  metro: 'metro',
  faStar: 'faStar',
  faStarFill: 'faStarFill',
  star: 'star',
  starCircle: 'starCircle',
  photo: 'photo',
  video: 'video',
  videoBild: 'videoBild',
  brushCircle: 'brushCircle',
  note: 'note',
  notes: 'notes',
  commentsCountN: 'commentsCountN',
  stock: 'stock',
  groups: 'groups',
  comment: 'comment',
  commentInvert: 'commentInvert',
  commentRound: 'commentRound',
  comments: 'comments',
  commentsOutline: 'commentsOutline',
  iconFlower: 'iconFlower',
  iconCar: 'iconCar',
  iconWindows: 'iconWindows',
  iconMap: 'iconMap',
  iconBuilds: 'iconBuilds',
  iconDoc: 'iconDoc',
  iconDocProect: 'iconDocProect',
  iconStamp: 'iconStamp',
  iconBuildsBC: 'iconBuildsBC',
  iconHandshakeBC: 'iconHandshakeBC',
  iconShieldBC: 'iconShieldBC',
  iconDocumentsBC: 'iconDocumentsBC',
  addPlus: 'addPlus',
  addCircle: 'addCircle',
  minus: 'minus',
  minusCircle: 'minusCircle',
  planet: 'planet',
  planetThin: 'planetThin',
  planetThinFill: 'planetThinFill',
  phoneMob: 'phoneMob',
  phoneMonitor: 'phoneMonitor',
  phoneCalls: 'phoneCalls',
  phoneCallsBorder: 'phoneCallsBorder',
  phoneHandset: 'phoneHandset',
  complaintCircleAlmost: 'complaintCircleAlmost',
  exclamationBorder: 'exclamationBorder',
  letter: 'letter',
  letterBorderLighter: 'letterBorderLighter',
  letterBild: 'letterBild',
  viber: 'viber',
  viberFilling: 'viberFilling',
  telegram: 'telegram',
  telegramFill: 'telegramFill',
  messenger: 'messenger',
  viewApps: 'viewApps',
  viewSubject: 'viewSubject',
  viewList: 'viewList',
  viewTileBild: 'viewTileBild',
  viewListBild: 'viewListBild',
  // viewTileLong: 'viewTileLong',
  statistics: 'statistics',
  help: 'help',
  catNotFound: 'catNotFound',
  catHigh: 'catHigh',
  catMedium: 'catMedium',
  catLow: 'catLow',
  catNA: 'catNA',
  catHighFull: 'catHighFull',
  catMediumFull: 'catMediumFull',
  catLowFull: 'catLowFull',
  catNAFull: 'catNAFull',
  // catLike: 'catLike',
  catOk: 'catOk',
  catDemanding: 'catDemanding',
  catPayAttention: 'catPayAttention',
  // catCovered: 'catCovered',
  helpCircle: 'helpCircle',
  fastForward: 'fastForward',
  check: 'check',
  compare: 'compare',
  hide: 'hide',
  clear: 'clear',
  clearCircle: 'clearCircle',
  clearFilters: 'clearFilters',
  // moreHorizontal: 'moreHorizontal',
  moreVertical: 'moreVertical',
  visibility: 'visibility',
  visibilityOff: 'visibilityOff',
  mapFreedraw: 'mapFreedraw',
  mapCircle: 'mapCircle',
  locationFixed: 'locationFixed',
  locationMeasure: 'locationMeasure',
  locationSearching: 'locationSearching',
  locationDisabled: 'locationDisabled',
  socialInstagram: 'socialInstagram',
  socialFacebook: 'socialFacebook',
  socialGoogle: 'socialGoogle',
  socialTwitter: 'socialTwitter',
  socialLinkedin: 'socialLinkedin',
  socialPinterest: 'socialPinterest',
  socialYoutube: 'socialYoutube',
  copyLink: 'copyLink',
  edit: 'edit',
  editDomik: 'editDomik',
  iconEmoji: 'iconEmoji',
  // emojiSadness: 'emojiSadness',
  emojiContrast: 'emojiContrast',
  emojiSadnessContrast: 'emojiSadnessContrast',
  paste: 'paste',
  mapPoly: 'mapPoly',
  brush: 'brush',
  compareBtn: 'compareBtn',
  delete: 'delete',
  playlistAdd: 'playlistAdd',
  replay: 'replay',
  mapPlace: 'mapPlace',
  walk: 'walk',
  searchedFor: 'searchedFor',
  dragging: 'dragging',
  rotateRight: 'rotateRight'
};

const fontMaps = {
  [iconTypes.arrowUp]: '\ue90a',
  // [iconTypes.arrowDown]: '\ue90a',
  [iconTypes.arrowLeft]: '\ue90a',
  [iconTypes.arrowRight]: '\ue90a',
  [iconTypes.arrowTopMost]: '\ue90a',
  [iconTypes.converter]:
  '<span class="p_0">\ue90a</span>' +
  '<span class="p_1">\ue90b</span>' +
  '<span class="p_2">\ue90c</span>' +
  '<span class="p_3">\ue90d</span>',
  [iconTypes.lock]: '\ue90b',
  [iconTypes.anglesMultiCheck]:
  '<span class="p_0">\ue90c</span>' +
  '<span class="p_1">\ue90d</span>',
  // copy: '\ue90c',
  // youtube_link: '\ue90d',
  [iconTypes.checkCircle]: '\ue90e',
  [iconTypes.checkCircleBild]:
  '<span class="p_0">\ue90e</span>' +
  '<span class="p_1">\ue90f</span>',
  [iconTypes.tag]: '\ue90f',
  [iconTypes.taxi]: '\ue90f',
  [iconTypes.search]: '\ue900',
  [iconTypes.searchBild]: '\ue900',
  [iconTypes.save]: '\ue901',
  [iconTypes.formatBold]: '\ue901',
  [iconTypes.formatItalic]: '\ue902',
  [iconTypes.formatUnderline]: '\ue903',
  [iconTypes.formatStrike]: '\ue904',
  [iconTypes.quote]: '\ue905',
  [iconTypes.formatQuote]: '\ue905',
  [iconTypes.arrowSliderLeft]: '\ue906',
  [iconTypes.arrowSliderRight]: '\ue906',
  [iconTypes.settings]: '\ue907',
  [iconTypes.affix]: '\ue908',
  [iconTypes.carret]: '\ue909',
  [iconTypes.carretRight]: '\ue909',
  [iconTypes.carretBild]: '\ue909',
  [iconTypes.receipt]: '\ue910',
  [iconTypes.cart]: '\ue911',
  [iconTypes.dropdown]: '\ue912',
  [iconTypes.moneyLong]: '\ue913',
  [iconTypes.moneyBank]: '\ue913',
  [iconTypes.installment]: '\ue913',
  /*
  logoNew:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>' +
  '<span class="p_2">\ue916</span>' +
  '<span class="p_3">\ue917</span>' +
  '<span class="p_4">\ue918</span>' +
  '<span class="p_5">\ue919</span>' +
  '<span class="p_6">\ue91a</span>' +
  '<span class="p_7">\ue91b</span>' +
  '<span class="p_8">\ue91c</span>' +
  '<span class="p_9">\ue91d</span>' +
  '<span class="p_10">\ue91e</span>' +
  '<span class="p_11">\ue91f</span>',
  */
  [iconTypes.logoV]: '\ue914',
  [iconTypes.logoDomik]:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>' +
  '<span class="p_2">\ue916</span>',
  [iconTypes.logoForum]:
  '<span class="p_0">\ue915</span>' +
  '<span class="p_1">\ue916</span>',
  [iconTypes.logoDomikInForum]: '\ue915',
  [iconTypes.formatUnorderedList]: '\ue916',
  [iconTypes.formatOrderedList]: '\ue917',
  [iconTypes.logoBild]: '\ue914',
  /*
  logoNew_white:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>' +
  '<span class="p_2">\ue916</span>' +
  '<span class="p_3">\ue917</span>' +
  '<span class="p_4">\ue918</span>' +
  '<span class="p_5">\ue919</span>' +
  '<span class="p_6">\ue91a</span>' +
  '<span class="p_7">\ue91b</span>' +
  '<span class="p_8">\ue91c</span>' +
  '<span class="p_9">\ue91d</span>' +
  '<span class="p_10">\ue91e</span>' +
  '<span class="p_11">\ue91f</span>',
  */
  [iconTypes.logoDotsWhite]:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>' +
  '<span class="p_2">\ue916</span>',
  [iconTypes.logoBildPotato]:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>',
  [iconTypes.logoBildSquareWhite]:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>' +
  '<span class="p_2">\ue916</span>',
  [iconTypes.logoBildControl]: '\ue914',
  [iconTypes.logoBildControlText]: '\ue914',
  [iconTypes.logoA2]:
  '<span class="p_0">\ue914</span>' +
  '<span class="p_1">\ue915</span>',
  [iconTypes.favoritesBorder]: '\ue92a',
  [iconTypes.newTab]: '\ue92b',
  [iconTypes.built]: '\ue92c',
  [iconTypes.power]: '\ue92d',
  [iconTypes.sortVert]: '\ue92e',
  [iconTypes.send]: '\ue92f',
  [iconTypes.logIn]: '\ue920',
  [iconTypes.logOut]: '\ue920',
  [iconTypes.menu]: '\ue921',
  [iconTypes.menuDomik]:
  '<span class="p_0">\ue921</span>' +
  '<span class="p_1">\ue922</span>' +
  '<span class="p_2">\ue923</span>',
  [iconTypes.favorites]: '\ue922',
  [iconTypes.bed]: '\ue923',
  [iconTypes.person]: '\ue924',
  [iconTypes.persons]: '\ue924',
  [iconTypes.personCircle]: '\ue924',
  [iconTypes.personInSquare]: '\ue924',
  [iconTypes.dns]: '\ue925',
  [iconTypes.laptop]: '\ue926',
  [iconTypes.laptopMonitor]: '\ue926',
  [iconTypes.notification]: '\ue927',
  [iconTypes.notificationNone]: '\ue927',
  [iconTypes.pages]: '\ue928',
  [iconTypes.share]: '\ue929',
  [iconTypes.moreLabel]: '\ue93a',
  [iconTypes.build]: '\ue93b',
  [iconTypes.builds]: '\ue93b',
  /*
  [iconTypes.buildsFilled]:
  '<span class="p_0">\ue93b</span>' +
  '<span class="p_1">\ue93c</span>' +
  '<span class="p_2">\ue93d</span>' +
  '<span class="p_3">\ue93e</span>' +
  '<span class="p_4">\ue93f</span>' +
  '<span class="p_5">\ue940</span>' +
  '<span class="p_6">\ue941</span>' +
  '<span class="p_7">\ue942</span>' +
  '<span class="p_8">\ue943</span>',
  */
  // [iconTypes.checkAll]: '\ue93c',
  [iconTypes.overflight]: '\ue93c',
  [iconTypes.developers]: '\ue93c',
  // [iconTypes.developersFilled]: '\ue93c',
  [iconTypes.questionAnswer]: '\ue93d',
  [iconTypes.discussion]: '\ue93d',
  [iconTypes.calendar]: '\ue93e',
  [iconTypes.clock]: '\ue93f',
  [iconTypes.checkbox]: '\ue930',
  [iconTypes.checkboxChecked]:
  '<span class="p_0">\ue930</span>' +
  '<span class="p_1">\ue931</span>',
  [iconTypes.checkboxCheckedAll]: '\ue930',
  [iconTypes.reply]: '\ue931',
  [iconTypes.votingUp]: '\ue932',
  [iconTypes.votingDown]: '\ue932',
  [iconTypes.radiobox]: '\ue933',
  [iconTypes.radioboxChecked]: '\ue933',
  [iconTypes.timelapse]: '\ue934',
  [iconTypes.trendingUp]: '\ue935',
  [iconTypes.screen]: '\ue936',
  [iconTypes.fullscreen]: '\ue936',
  [iconTypes.gift]: '\ue937',
  // [iconTypes.giftFilled]: '\ue937',
  [iconTypes.finflash]: '\ue937',
  [iconTypes.marker]: '\ue938',
  [iconTypes.markerBorder]: '\ue938',
  [iconTypes.markerBorderLighter]: '\ue938',
  // [iconTypes.markerFilled]: '\ue938',
  [iconTypes.refresh]: '\ue939',
  [iconTypes.filter]: '\ue94a',
  [iconTypes.imageUndefined]: '\ue94b',
  [iconTypes.announcement]: '\ue94c',
  [iconTypes.information]: '\ue94c',
  [iconTypes.infoCircleBorder]: '\ue94c',
  [iconTypes.codeSettings]: '\ue94d',
  // [iconTypes.percent]: '\ue94e',
  [iconTypes.ratingIN]: '\ue94f',
  [iconTypes.bc]:
  '<span class="p_0">\ue94f</span>' +
  '<span class="p_1">\ue950</span>' +
  '<span class="p_2">\ue951</span>' +
  '<span class="p_3">\ue952</span>' +
  '<span class="p_4">\ue953</span>',
  [iconTypes.folder]: '\ue940',
  [iconTypes.folderOpen]: '\ue940',
  [iconTypes.club]: '\ue941',
  [iconTypes.flag]: '\ue942',
  // pdf: '\ue943',
  [iconTypes.crown]: '\ue943',
  [iconTypes.metro]: '\ue944',
  [iconTypes.faStar]: '\ue945',
  [iconTypes.faStarFill]: '\ue946',
  [iconTypes.star]: '\ue945',
  [iconTypes.starCircle]: '\ue945',
  [iconTypes.photo]: '\ue946',
  [iconTypes.video]: '\ue946',
  [iconTypes.videoBild]: '\ue946',
  [iconTypes.brushCircle]: '\ue947',
  [iconTypes.note]: '\ue948',
  [iconTypes.notes]: '\ue948',
  [iconTypes.statistics]: '\ue949',
  [iconTypes.help]: '\ue949',
  [iconTypes.catNotFound]:
  '<span class="p_0">\ue950</span>' +
  '<span class="p_1">\ue951</span>' +
  '<span class="p_2">\ue952</span>' +
  '<span class="p_3">\ue953</span>',
  [iconTypes.catHigh]: '\ue950',
  [iconTypes.catMedium]: '\ue951',
  [iconTypes.catLow]: '\ue952',
  [iconTypes.catNA]: '\ue953',
  [iconTypes.catHighFull]:
  '<span class="p_0">\ue950</span>' +
  '<span class="p_1">\ue951</span>' +
  '<span class="p_2">\ue952</span>' +
  '<span class="p_3">\ue953</span>' +
  '<span class="p_4">\ue954</span>' +
  '<span class="p_5">\ue955</span>',
  [iconTypes.catMediumFull]:
  '<span class="p_0">\ue951</span>' +
  '<span class="p_1">\ue952</span>' +
  '<span class="p_2">\ue953</span>' +
  '<span class="p_3">\ue954</span>' +
  '<span class="p_4">\ue955</span>' +
  '<span class="p_5">\ue956</span>' +
  '<span class="p_6">\ue957</span>' +
  '<span class="p_7">\ue958</span>' +
  '<span class="p_8">\ue959</span>' +
  '<span class="p_9">\ue95a</span>' +
  '<span class="p_10">\ue95b</span>' +
  '<span class="p_11">\ue95c</span>' +
  '<span class="p_12">\ue95d</span>' +
  '<span class="p_13">\ue95e</span>' +
  '<span class="p_14">\ue95f</span>',
  [iconTypes.catLowFull]:
  '<span class="p_0">\ue952</span>' +
  '<span class="p_1">\ue953</span>' +
  '<span class="p_2">\ue954</span>' +
  '<span class="p_3">\ue955</span>' +
  '<span class="p_4">\ue956</span>' +
  '<span class="p_5">\ue957</span>' +
  '<span class="p_6">\ue958</span>' +
  '<span class="p_7">\ue959</span>' +
  '<span class="p_8">\ue95a</span>' +
  '<span class="p_9">\ue95b</span>' +
  '<span class="p_10">\ue95c</span>' +
  '<span class="p_11">\ue95d</span>' +
  '<span class="p_12">\ue95e</span>' +
  '<span class="p_13">\ue95f</span>' +
  '<span class="p_14">\ue960</span>' +
  '<span class="p_15">\ue961</span>',
  [iconTypes.catNAFull]:
  '<span class="p_0">\ue953</span>' +
  '<span class="p_1">\ue954</span>' +
  '<span class="p_2">\ue955</span>' +
  '<span class="p_3">\ue956</span>' +
  '<span class="p_4">\ue957</span>' +
  '<span class="p_5">\ue958</span>' +
  '<span class="p_6">\ue959</span>' +
  '<span class="p_7">\ue95a</span>' +
  '<span class="p_8">\ue95b</span>' +
  '<span class="p_9">\ue95c</span>' +
  '<span class="p_10">\ue95d</span>' +
  '<span class="p_11">\ue95e</span>',
  /*
  [iconTypes.catLike]:
  '<span class="p_0">\ue954</span>' +
  '<span class="p_1">\ue955</span>' +
  '<span class="p_2">\ue956</span>' +
  '<span class="p_3">\ue957</span>' +
  '<span class="p_4">\ue958</span>' +
  '<span class="p_5">\ue959</span>' +
  '<span class="p_6">\ue95a</span>' +
  '<span class="p_7">\ue95b</span>' +
  '<span class="p_8">\ue95c</span>' +
  '<span class="p_9">\ue95d</span>' +
  '<span class="p_10">\ue95e</span>' +
  '<span class="p_11">\ue95f</span>' +
  '<span class="p_12">\ue960</span>' +
  '<span class="p_13">\ue961</span>' +
  '<span class="p_14">\ue962</span>' +
  '<span class="p_15">\ue963</span>' +
  '<span class="p_16">\ue964</span>' +
  '<span class="p_17">\ue965</span>',
  */
  [iconTypes.catOk]:
  '<span class="p_0">\ue954</span>' +
  '<span class="p_1">\ue955</span>' +
  '<span class="p_2">\ue956</span>' +
  '<span class="p_3">\ue957</span>' +
  '<span class="p_4">\ue958</span>' +
  '<span class="p_5">\ue959</span>' +
  '<span class="p_6">\ue95a</span>' +
  '<span class="p_7">\ue95b</span>' +
  '<span class="p_8">\ue95c</span>' +
  '<span class="p_9">\ue95d</span>' +
  '<span class="p_10">\ue95e</span>' +
  '<span class="p_11">\ue95f</span>' +
  '<span class="p_12">\ue960</span>' +
  '<span class="p_13">\ue961</span>' +
  '<span class="p_14">\ue962</span>' +
  '<span class="p_15">\ue963</span>',
  [iconTypes.catDemanding]:
  '<span class="p_0">\ue954</span>' +
  '<span class="p_1">\ue955</span>' +
  '<span class="p_2">\ue956</span>' +
  '<span class="p_3">\ue957</span>' +
  '<span class="p_4">\ue958</span>' +
  '<span class="p_5">\ue959</span>' +
  '<span class="p_6">\ue95a</span>' +
  '<span class="p_7">\ue95b</span>' +
  '<span class="p_8">\ue95c</span>' +
  '<span class="p_9">\ue95d</span>' +
  '<span class="p_10">\ue95e</span>' +
  '<span class="p_11">\ue95f</span>' +
  '<span class="p_12">\ue960</span>' +
  '<span class="p_13">\ue961</span>' +
  '<span class="p_14">\ue962</span>' +
  '<span class="p_15">\ue963</span>' +
  '<span class="p_16">\ue964</span>' +
  '<span class="p_17">\ue965</span>' +
  '<span class="p_18">\ue966</span>' +
  '<span class="p_19">\ue967</span>' +
  '<span class="p_20">\ue968</span>' +
  '<span class="p_21">\ue969</span>' +
  '<span class="p_22">\ue96a</span>',
  [iconTypes.catPayAttention]:
  '<span class="p_0">\ue954</span>' +
  '<span class="p_1">\ue955</span>' +
  '<span class="p_2">\ue956</span>' +
  '<span class="p_3">\ue957</span>' +
  '<span class="p_4">\ue958</span>' +
  '<span class="p_5">\ue959</span>' +
  '<span class="p_6">\ue95a</span>' +
  '<span class="p_7">\ue95b</span>' +
  '<span class="p_8">\ue95c</span>' +
  '<span class="p_9">\ue95d</span>' +
  '<span class="p_10">\ue95e</span>' +
  '<span class="p_11">\ue95f</span>' +
  '<span class="p_12">\ue960</span>' +
  '<span class="p_13">\ue961</span>' +
  '<span class="p_14">\ue962</span>' +
  '<span class="p_15">\ue963</span>' +
  '<span class="p_16">\ue964</span>',
  /*
  [iconTypes.catCovered]:
  '<span class="p_0">\ue954</span>' +
  '<span class="p_1">\ue955</span>' +
  '<span class="p_2">\ue956</span>' +
  '<span class="p_3">\ue957</span>' +
  '<span class="p_4">\ue958</span>' +
  '<span class="p_5">\ue959</span>' +
  '<span class="p_6">\ue95a</span>' +
  '<span class="p_7">\ue95b</span>' +
  '<span class="p_8">\ue95c</span>' +
  '<span class="p_9">\ue95d</span>' +
  '<span class="p_10">\ue95e</span>' +
  '<span class="p_11">\ue95f</span>' +
  '<span class="p_12">\ue960</span>' +
  '<span class="p_13">\ue961</span>' +
  '<span class="p_14">\ue962</span>' +
  '<span class="p_15">\ue963</span>' +
  '<span class="p_16">\ue964</span>' +
  '<span class="p_17">\ue965</span>' +
  '<span class="p_18">\ue966</span>' +
  '<span class="p_19">\ue967</span>' +
  '<span class="p_20">\ue968</span>' +
  '<span class="p_21">\ue968</span>',
  */
  [iconTypes.commentsCountN]: '\ue953',
  [iconTypes.stock]: '\ue954',
  // placeholder: '\ue955',
  // placeholder_photo: '\ue955',
  [iconTypes.groups]: '\ue956',
  [iconTypes.comment]: '\ue957',
  [iconTypes.commentInvert]: '\ue957',
  [iconTypes.commentRound]: '\ue957',
  [iconTypes.comments]: '\ue957',
  [iconTypes.commentsOutline]: '\ue957',
  [iconTypes.iconFlower]: '\ue958',
  [iconTypes.iconCar]: '\ue958',
  [iconTypes.iconWindows]: '\ue958',
  [iconTypes.iconMap]: '\ue958',
  [iconTypes.iconBuilds]:
  '<span class="p_0">\ue958</span>' +
  '<span class="p_1">\ue959</span>' +
  '<span class="p_2">\ue95a</span>' +
  '<span class="p_3">\ue95b</span>' +
  '<span class="p_4">\ue95c</span>' +
  '<span class="p_5">\ue95d</span>' +
  '<span class="p_6">\ue95e</span>' +
  '<span class="p_7">\ue95f</span>' +
  '<span class="p_8">\ue960</span>' +
  '<span class="p_9">\ue961</span>' +
  '<span class="p_10">\ue962</span>' +
  '<span class="p_11">\ue963</span>' +
  '<span class="p_12">\ue964</span>' +
  '<span class="p_13">\ue965</span>' +
  '<span class="p_14">\ue966</span>' +
  '<span class="p_15">\ue967</span>',
  [iconTypes.iconDoc]: '\ue958',
  [iconTypes.iconDocProect]: '\ue958',
  [iconTypes.iconStamp]: '\ue958',
  [iconTypes.iconBuildsBC]: '\ue958',
  [iconTypes.iconHandshakeBC]: '\ue958',
  [iconTypes.iconShieldBC]: '\ue958',
  [iconTypes.iconDocumentsBC]: '\ue958',
  [iconTypes.addPlus]: '\ue959',
  [iconTypes.addCircle]: '\ue959',
  [iconTypes.minusCircle]: '\ue96a',
  [iconTypes.planet]: '\ue960',
  [iconTypes.planetThin]: '\ue960',
  [iconTypes.planetThinFill]:
  '<span class="p_0">\ue960</span>' +
  '<span class="p_1">\ue961</span>' +
  '<span class="p_2">\ue962</span>',
  [iconTypes.phoneMob]: '\ue961',
  [iconTypes.phoneMonitor]: '\ue961',
  // minimize: '\ue962',
  [iconTypes.phoneCalls]: '\ue963',
  [iconTypes.phoneCallsBorder]: '\ue963',
  [iconTypes.phoneHandset]: '\ue963',
  // complaint_circle: '\ue964',
  [iconTypes.complaintCircleAlmost]: '\ue964',
  [iconTypes.exclamationBorder]: '\ue964',
  [iconTypes.minus]: '\ue965',
  // undo: '\ue966',
  // favoriteList: '\ue967',
  [iconTypes.letter]: '\ue968',
  [iconTypes.letterBorderLighter]: '\ue968',
  [iconTypes.letterBild]: '\ue968',
  [iconTypes.viber]: '\ue969',
  [iconTypes.viberFilling]: '\ue969',
  // : '\ue97a',
  [iconTypes.telegram]: '\ue970',
  [iconTypes.telegramFill]:
  '<span class="p_0">\ue970</span>' +
  '<span class="p_1">\ue971</span>',
  [iconTypes.messenger]: '\ue971',
  [iconTypes.viewApps]: '\ue972',
  [iconTypes.viewSubject]: '\ue972',
  [iconTypes.viewList]: '\ue972',
  [iconTypes.viewTileBild]: '\ue972',
  [iconTypes.viewListBild]: '\ue972',
  // [iconTypes.viewTileLong]: '\ue972',
  [iconTypes.helpCircle]: '\ue973',
  [iconTypes.fastForward]: '\ue974',
  [iconTypes.check]: '\ue975',
  [iconTypes.compare]: '\ue976',
  [iconTypes.hide]: '\ue977',
  [iconTypes.clear]: '\ue977',
  [iconTypes.clearCircle]: '\ue977',
  [iconTypes.clearFilters]: '\ue977',
  // [iconTypes.moreHorizontal]: '\ue978',
  [iconTypes.moreVertical]: '\ue978',
  [iconTypes.visibility]: '\ue979',
  [iconTypes.visibilityOff]: '\ue979',
  [iconTypes.mapFreedraw]: '\ue98a',
  [iconTypes.mapCircle]: '\ue98b',
  [iconTypes.locationFixed]: '\ue98c',
  [iconTypes.locationMeasure]: '\ue98d',
  [iconTypes.locationSearching]: '\ue98e',
  [iconTypes.locationDisabled]: '\ue98f',
  [iconTypes.socialInstagram]: '\ue980',
  [iconTypes.socialFacebook]: '\ue980',
  [iconTypes.socialGoogle]:
  '<span class="p_0">\ue980</span>' +
  '<span class="p_1">\ue981</span>' +
  '<span class="p_2">\ue982</span>' +
  '<span class="p_3">\ue983</span>',
  [iconTypes.socialTwitter]: '\ue980',
  [iconTypes.socialLinkedin]: '\ue980',
  [iconTypes.socialPinterest]: '\ue980',
  [iconTypes.socialYoutube]: '\ue980',
  [iconTypes.copyLink]: '\ue981',
  [iconTypes.edit]: '\ue982',
  [iconTypes.editDomik]: '\ue982',
  [iconTypes.iconEmoji]: '\ue983',
  // [iconTypes.emojiSadness]: '\ue983',
  [iconTypes.emojiContrast]: '\ue983',
  [iconTypes.emojiSadnessContrast]: '\ue983',
  [iconTypes.paste]: '\ue984',
  [iconTypes.mapPoly]: '\ue985',
  [iconTypes.brush]: '\ue986',
  [iconTypes.compareBtn]: '\ue987',
  [iconTypes.delete]: '\ue988',
  [iconTypes.playlistAdd]: '\ue989',
  [iconTypes.replay]: '\ue99a',
  [iconTypes.mapPlace]: '\ue99b',
  [iconTypes.walk]: '\ue99c',
  // : '\ue99d',
  [iconTypes.searchedFor]: '\ue99e',
  [iconTypes.dragging]: '\ue99f',
  [iconTypes.rotateRight]: '\ue990'
};

const socialsIconConfig = {
  viber: iconTypes.viber,
  telegram: iconTypes.telegram,
  fb: iconTypes.socialFacebook,
  // google: iconTypes.socialGoogle,
  linkedin: iconTypes.socialLinkedin,
  youtube: iconTypes.socialYoutube,
  instagram: iconTypes.socialInstagram,
  twitter: iconTypes.socialTwitter
};

export {
  iconTypes as default,
  fontMaps,
  socialsIconConfig
};
