/**
 * Created by sem on 09.06.17.
 */
import update from 'react-addons-update';
import getStore from 'helpers/redux/access-store';
import { subscribe } from 'helpers/global-events';
import linksModifier from './modifier';
import {actionGeolocate} from "../../geolocation/redux";

const LOAD = 'geolandings/LOAD';
const LOAD_SUCCESS = 'geolandings/LOAD_SUCCESS';
const LOAD_FAILURE = 'geolandings/LOAD_FAILURE';

const initialState = {
  action: null,
  loading: false,
  loaded: false,
  failure: false,
  location: null,
  data: {
  }
};

// i18n re-request
let lastAction = null;
subscribe('gLangChanged', () => {
  if (!lastAction) return;
  const store = getStore();
  store.dispatch(actionLoad(...lastAction));
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: true },
        failure: { $set: false },
      });

    case LOAD_SUCCESS:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        loaded: { $set: true },
        failure: { $set: false },
        location: { $set: action.location },
        data: { $set: linksModifier(action.payload.data) }
      });

    case LOAD_FAILURE:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        failure: { $set: true },
      });

    default:
      return state;
  }
}

export function actionLoad(project, location) {
  lastAction = [project, location];
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    location,
    apiRequest: {
      method: 'get',
      url: '/data-cache/1440/core/menu',
      params: { project, location }
    }
  };
}

