import React, { Component } from 'react';
import { routerShape } from 'react-router/lib/PropTypes';
import PropTypes from 'prop-types';
import btoa from 'btoa';
import get from 'lodash/get';
import { xenc } from 'api/middlewares/xor-middleware';
import Bem from 'bemHelper';
import { I18nPropHoc, langIsoMapBack } from 'helpers/i18n';
import marketStatPush, { marketStatEvents } from 'subsys/market-stat';
import NoIndex from 'components/no-index';
import sensor from 'components/sensor';
import Button from 'components/button';

import config from '../../../config';

import 'styles/base/components/link/_link.scss';

const classes = new Bem('link');

@I18nPropHoc()
@sensor
export default class Link extends Component {
  static propTypes = {
    // Link target URL
    href: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array
    ]),

    // Link Title
    title: PropTypes.string,

    // Project - if we references to another subproject
    project: PropTypes.string,

    // Is link external (to foreign domain)?
    external: PropTypes.bool,

    // Save scroll position when URL changes
    saveScrollState: PropTypes.bool,

    // Link Builder function
    builder: PropTypes.func,

    // Props for builder
    builderProps: PropTypes.shape({}),

    // Query string
    query: PropTypes.shape({}),

    // *** Events
    // fires when user clicks to link
    onClick: PropTypes.func,

    // *** Modifiers
    inlineBlock: PropTypes.bool,
    className: PropTypes.string,

    // *** System
    children: PropTypes.node.isRequired,

    // Anchors props
    rel: PropTypes.string,
    blank: PropTypes.bool,

    // props for 'data-...'
    dataProps: PropTypes.shape({}),

    // props for SEO
    seoProps: PropTypes.shape({}),

    // Synthetic events like Focus, MouseUp, MouseDown...
    addEvents: PropTypes.shape({}),

    // вместо findDOMNode
    linkRef: PropTypes.func,

    noIndex: PropTypes.bool,

    // Disable automatical redirector replacement for external links
    // By default all external links replaces witith: http(s)://blabla.com => /service/go?to=blabla.com
    noRedirector: PropTypes.bool
  };

  static defaultProps = {
    external: false,
    saveScrollState: false,
    builderProps: {}
  };

  static contextTypes = {
    router: routerShape
  };

  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
    this.renderLink = this.renderLink.bind(this);
    this.checkIsCycleLink = this.checkIsCycleLink.bind(this);
    this.toGeneratedLink = this.toGeneratedLink.bind(this);
    this.router = context.router;
    this.state = {
      __PROJECT__: __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__
    };
  }

  static getDerivedStateFromProps(props, state) {
    let linkProps = {
      href: props.href,
      title: props.title,
      external: props.external,
      query: props.query,
      hash: props.hash,
      project: props.project || state.__PROJECT__
    };

    const builderProps = typeof props.builderProps === 'object' ? props.builderProps : {};
    builderProps.langPrefix = props.nonDefaultLang ? `${langIsoMapBack[props.nonDefaultLang]}/` : '';

    if (props.builder) {
      linkProps = {
        ...linkProps,
        ...props.builder(builderProps)
      };
      linkProps.project = props.builder.project;
    }

    if (state.__PROJECT__ !== linkProps.project && !builderProps.forceInternal) {
      linkProps.href = `//${config.domains[linkProps.project]}${linkProps.href}`;
      linkProps.external = true;
    }
    return linkProps;
  }

  pushToDataLayer() {
    const linkData = {
      'target': 'other',
      'promo': 'false',
      'place': 'other',
      'type': 'other',
      ...get(this, 'props.dataProps')
  };

    marketStatPush(marketStatEvents.push, 'externalLink', linkData);
  }

  handleClick(event) {
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) return;
    if (this.props.onClick && (this.props.onClick(event) === false)) {
      event.preventDefault();
      return;
    }
    if (event.defaultPrevented) return;

    const router = this.context.router;

    const { href, external, query, hash } = this.state;

    if (!href) {
      event.preventDefault();
      return null;
    }

    const isRelative = !external;

    // this.pushToDataLayer();
    const generatedLink = this.router.createHref({
      pathname: href,
      query,
      hash
    });

    if (isRelative) {
      event.preventDefault();
      const advProps = {};
      if (this.props.saveScrollState) {
        const { scrollLeft, scrollTop } = this.sensor.getRuntime();
        advProps.state = { scrollPosition: [scrollLeft, scrollTop] };
      }
      if (this.props.blank) {
        return window.open(`${generatedLink}`, '_blank');
      }
      router.push({ pathname: href, query, hash, ...advProps });
    } else {
      if (!this.props.blank) {
        //return window.open(generatedLink, '_blank');
        event.preventDefault();
        return window.location = generatedLink;
      }
    }
  }

  toGeneratedLink() {
    const { __PROJECT__, href, query } = this.state;
    const url = this.router.createHref({ pathname: href, query });
    const generatedLink = this.router.createHref({ pathname: '/service/go',
      query: {
        to: btoa(xenc(`url=${url}`)),
        project: __PROJECT__
      }
    });

    if (this.props.onClick) this.props.onClick();

    this.pushToDataLayer();

    return window.open(`${generatedLink}`, '_blank');
  }

  checkIsCycleLink(generatedLink) {
    const locationPathname = get(this, 'router.location.pathname');
    const locationSearch = get(this, 'router.location.search');
    const isCycleLink = locationPathname === generatedLink && !locationSearch;

    return isCycleLink;
  }

  renderLink() {
    const {
      className, inlineBlock, children, rel, blank, linkRef, noRedirector,
      dataProps = {}, seoProps = {}, addEvents = {}
    } = this.props;
    const { href, title, query, hash } = this.state;

    const additionalProps = {
      ...dataProps,
      ...seoProps,
      ...addEvents
    };
    if (rel) additionalProps.rel = rel;
    if (blank) additionalProps.target = '_blank';

    let generatedLink = this.router.createHref({ pathname: href, query, hash });
    if (
      !noRedirector && (
        generatedLink.indexOf('http://') > -1 ||
        generatedLink.indexOf('https://') > -1 ||
        generatedLink.match(/^\/\//)
      )) {
      const dMatch = generatedLink.match(/^(http(s?):\/\/|\/\/)(\S+?)(\/|$)/);
      const eDomain = get(dMatch, '[2]');

      if (config.whitelistDomains.indexOf(eDomain) === -1) {
        return (
          <Button
            {...classes(null, { inlineBlock, asLink: true }, className)}
            //{...additionalProps}
            //{...additionalProps.rel = null}
            //{...additionalProps.target = null}
            ref={(node) => { if (linkRef) linkRef(node); }}
            onClick={this.toGeneratedLink}
            title={title}
            customRenderer
            children={children}
          />
        );
      }
    }

    const isCycleLink = this.checkIsCycleLink(generatedLink);

    if (isCycleLink) {
      return (
        <span
          {...classes(null, { inlineBlock, active: true }, this.props.className)}
          {...additionalProps}
          ref={(node) => { if (linkRef) linkRef(node); }}
          title={title}
          children={children}
        />
      );
    }

    const linkContentType = get(this, 'props.builderProps.contentType');
    const currentContentType = get(this, 'router.params.landing.section');
    const active =
      href === get(this, 'router.params.landing.url') ||
      (linkContentType === currentContentType && linkContentType);

    return (
      <a
        {...classes(null, { inlineBlock, active }, this.props.className)}
        {...additionalProps}
        ref={(node) => { if (linkRef) linkRef(node); }}
        onClick={this.handleClick}
        href={generatedLink}
        title={title}
        children={children}
      />
    );
  }

  render() {
    if (this.props.noIndex) {
      return (
        <NoIndex>
          {this.renderLink()}
        </NoIndex>
      );
    }

    return this.renderLink();
  }
}

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
