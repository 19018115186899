import { langFromUrl } from 'helpers/i18n';
export default function setLang(lang) {
  return {
    url: (url) => {
      if (__CLIENT__ && !lang) {
        const fromUrl = langFromUrl(window.location.pathname);
        return `${fromUrl}/${url}`;
      }
      if (lang) return `${lang}/${url}`;
      return url;
    }
  }
}
