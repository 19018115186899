/**
 * Created by dryymoon on 29.11.16.
 */

const types = {
  OPEN: 'dropDown/OPEN',
  CLOSE: 'dropDown/CLOSE',
  DESTROYED: 'dropDown/DESTROYED'
};

export function actionOpen({ id, group }) {
  return { type: types.OPEN, id, group };
}

export function actionClose({ id, group }) {
  return { type: types.CLOSE, id, group };
}
export function actionDestroyed({ id, group }) {
  return { type: types.DESTROYED, id, group };
}

export const initialState = {
  whichOpened: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.OPEN:
      return { ...state, whichOpened: action.id };

    case types.CLOSE:
      return { ...state, whichOpened: null };

    case types.DESTROYED:
      if (state.whichOpened === action.id) {
        return { ...state, whichOpened: null };
      }
      return { ...state };

    default:
      return state;
  }
}

