import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Row, { Col } from 'components/row';
import Link from 'components/link';

import imgMain from 'src-sites/a2/section-home/assets/main.jpg';

import translates from './not-found-i18n.json';

const bemClasses = new Bem('pageNotFound');

@I18nHoc(translates)
export default class ContentA2 extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { i18n } = this;

    return (
      <div {...bemClasses({ extra: this.props.className })}>
        <Row {...bemClasses('row', 'block')} mtColumn mpColumn>
          <Col {...bemClasses('col', 'img')} allPr={0}>
            <div {...bemClasses('imgContainer')}>
              <img {...bemClasses('imgMain')} src={imgMain} alt="404" />
              <div {...bemClasses('imgMainSubstrate')} />
            </div>
          </Col>
          <Col {...bemClasses('col', 'info')} allFlex allPr={0}>
            <Row {...bemClasses('row', 'description')} mpColumn>
              <Col {...bemClasses('col', 'description')} allAuto allPr={0}>
                <span {...bemClasses('text', 'description')} children={i18n('a2Error404')} />
              </Col>
              <Col {...bemClasses('col', 'link')} allAuto allPr={0}>
                <Link
                  {...bemClasses('link')}
                  href="/"
                  title={i18n('a2ToMain')}
                  blank
                >
                  <span {...bemClasses('linkContent')}>
                    <span {...bemClasses('shadow')} />
                    <span {...bemClasses('text', 'link')} children={i18n('a2ToMainText')} />
                  </span>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
