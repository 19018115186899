import isError from 'lodash/isError';
/**
 * Data Preloader error
 */
export default class PreloaderError extends Error {
  constructor(...args) {
    super(...args);
    if (isError(args[0])) {
      this.stack = args[0].stack;
    }
    this.name = 'Data Preloader Error';
  }
}
