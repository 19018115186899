import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import clone from 'lodash/clone';
import Loader from 'components/loader';
import ApiError from 'errors/apiError';
import { subscribe, unsubscribe } from 'helpers/global-events';

import { getErrorMessage } from './api-errors';
import apiDecorator from './api-decorator';

// import DataProvider from './data-provider';

const promiseCache = {};

// Stores temporary data cache
// for correct "back-forward" works
let lruDataCache = {};

function stateFromPreloadedData(props, state) {
  return {
    reloadCount: state.reloadCount + 1,
    loading: false,
    loaded: true,
    data: props.preloaded,
    rawData: props.dataModifier && (props.preloaded || null)
  };
}

@apiDecorator
export default class DataLoader extends PureComponent {
  static propTypes = {
    method: PropTypes.oneOf(['get', 'post', 'put']), // eslint-disable-line
    urlPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // eslint-disable-line
    url: PropTypes.string,
    // eslint-disable-next-line
    query: PropTypes.object,
    // Transform query object to JSON
    queryJson: PropTypes.bool, // eslint-disable-line
    // eslint-disable-next-line
    preloaded: PropTypes.any,
    // Work only with preloaded data, not run requests when query/url changed
    // This behavior uses in client pages with dynamic preloaders
    preloadedStatic: PropTypes.bool,
    injectPropName: PropTypes.string,
    /**
     * Render method.
     * list: iterate a response data, and clone child element with injecting an each element as prop
     * single: inject all response data to one child
     */
    renderMethod: PropTypes.oneOf(['list', 'single']),
    apiOptions: PropTypes.shape({}), // eslint-disable-line
    injectFromLoader: PropTypes.array, // eslint-disable-line
    dataModifier: PropTypes.func,
    dataModifierProps: PropTypes.any, // eslint-disable-line
    // Cache request data to some time for "back - forward" works
    lruCahce: PropTypes.bool, // eslint-disable-line
    children: PropTypes.node,
    renderer: PropTypes.func,
    // Fires when data loaded and component updated
    onLoaded: PropTypes.func,
    className: PropTypes.any, // eslint-disable-line
    tagSpan: PropTypes.bool,
    // Render data in any case
    renderUnloaded: PropTypes.bool,
    // Растягивает лоадер на весь контейнер
    loaderFill: PropTypes.bool,
    // Calls when error happens
    onError: PropTypes.func,
    // Calls when data loaded/reloaded
    onLoad: PropTypes.func,
    // Do not show loader, when data reloading
    silentReload: PropTypes.bool,
    // Fully disable loader
    disableLoader: PropTypes.bool
  };

  static defaultProps = {
    method: 'get',
    urlPrefix: '/',
    query: {},
    queryJson: false,
    // preloaded: null,
    injectPropName: 'data',
    renderMethod: 'single',
    injectFromLoader: [],
    single: false,
    tagSpan: false,
    lruCache: false,
    preloadedStatic: false,
    silentReload: false,
    disableLoader: false
  };

  static contextTypes = {
    preloadRunning: PropTypes.bool
  };

  static _preload(defaultProps, params) {
    const promise = DataLoader.request({ ...defaultProps, ...params }, params.api); // eslint-disable-line
    promise.catch((error) => {
      throw new ApiError(error, {
        component: 'DataPreloaderStaticPreload'
      });
    });
    return promise;
  }

  static preload(params = {}) {
    return DataLoader._preload(DataLoader.defaultProps, params);
  }

  static request(props, api) {
    const {
      method, urlPrefix: urlPrefixGet, url, query, queryJson, apiOptions, requestClientCache
    } = props;
    const urlPrefix = typeof urlPrefixGet === 'function' ? urlPrefixGet(props) : urlPrefixGet;
    const opts = {
      ignoreServerErrors: ['ValidatorException', 'UserException']
    };
    if (method === 'get') {
      opts.queryString = queryJson ? { json: JSON.stringify(query) } : query;
    } else {
      opts.postData = query;
    }

    if (requestClientCache) {
      opts.clientCache = requestClientCache;
    }

    /** Promise cache calls a floated bug with a lot of concurrent requests and multi-lang config.
     *  Also - this functionality is not cricital
    const promiseCacheKey = md5(urlPrefix + url + JSON.stringify(query));
    if (promiseCache[promiseCacheKey]) {
      return promiseCache[promiseCacheKey];
    }
    */

    const promise = api[method](urlPrefix + url, {
      ...opts,
      ...apiOptions
    });

    /*
    promiseCache[promiseCacheKey] = promise;
    promise
      .then((rv) => { // eslint-disable-line
        delete promiseCache[promiseCacheKey];
      })
      .catch((rv) => { // eslint-disable-line
        delete promiseCache[promiseCacheKey];
      });
    */

    return promise;
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {
      preloadRunning: false
    };

    // Non-preloaderStatic behavior
    // Update from preloader logic. If we had a running preloader in prev state and already loaded
    // preloader in current props - we need to update data from preloader
    if (props.preloaded && props.preloaded.$running) {
      newState.preloadRunning = true;
    }
    if (state.preloadRunning && ('preloaded' in props) && props.preloaded && !props.preloaded.$running) {
      newState = {
        ...newState,
        ...stateFromPreloadedData(props, state)
      };
    }

    // preloadedStatic behavior
    if (props.preloadedStatic) {
      newState = {
        ...newState,
        ...stateFromPreloadedData(props, state)
      };
    }

    return newState;
  }

  constructor(props, ctx) {
    super(props, ctx);

    let cachedData = null;
    // eslint-disable-next-line
    if (props.lruCache) {
      const key = md5(props.urlPrefix + props.url + JSON.stringify(props.query));
      cachedData = lruDataCache[key] || null;
      delete lruDataCache[key];
    }

    this.state = {
      loading: false,
      loaded: !!props.preloaded || cachedData !== null,
      error: false,
      reloadCount: 1,
      data: props.preloaded || cachedData || null,
      rawData: props.dataModifier && (props.preloaded || cachedData || null),
    };
    this.mounted = false;
    this.fireOnLoaded = !!props.preloaded || cachedData !== null;
    this.modifierProps = props.dataModifierProps;
    this.reload = this.reload.bind(this);
    this.fireOnLoadedIfNeeded();
    subscribe('gLangChanged', this._langChangeRerequest);
  }

  _langChangeRerequest = () => {
    if (this.props.preloadedStatic) return;
    this._langReload();
    this.reload();
  }

  _langReload() {
    lruDataCache = {};
    this.reload();
  }

  _dataFromPreload(props) {
    this.setState({
      reloadCount: this.state.reloadCount + 1,
      loading: false,
      loaded: true,
      data: props.preloaded,
      rawData: props.dataModifier && (props.preloaded || null)
    });
  }

  _getInjectingData() {
    return this.transformData(this.props.dataModifier ? this.state.rawData : this.state.data);
  }

  _getInjectingMetadata() {
    return this.getMetadata(this.props.dataModifier ? this.state.rawData : this.state.data);
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.state.loaded && (!('preloaded' in this.props) || !this.context.preloadRunning)) {
      this.load(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    // Run Reload request if needed
    if (
      !this.props.preloadedStatic &&
      (!isEqual(this.props.query, prevProps.query) ||
       !isEqual(this.props.url, prevProps.url))
    ) {
      this.load(this.props);
    }

    if (prevProps.preloadedStatic && !this.props.preloadedStatic) {
      this.load(this.props);
    }

    this.fireOnLoadedIfNeeded();
  }

  componentWillUnmount() {
    this.mounted = false;
    unsubscribe('gLangChanged', this._langChangeRerequest);
  }

  setState(state, callback) {
    if (!this.mounted) {
      return;
    }
    super.setState(state, callback);
  }

  /**
   * You can override it in inherited modules and override
   * @param data
   * @return {*}
   */
  getMetadata() {
    return {};
  }

  /**
   * You can override it in inherited modules and override
   * @param data
   * @return {*}
   */
  transformData(data) {
    const { dataModifier } = this.props;
    if (dataModifier) {
      return dataModifier(data, this.props.dataModifierProps);
    }
    return data;
  }


  fireOnLoadedIfNeeded() {
    if (this.fireOnLoaded && this.props.onLoaded) {
      this.props.onLoaded(this._getInjectingData(), this._getInjectingMetadata());
      this.fireOnLoaded = false;
    }
  }

  reload() {
    this.load(this.props);
  }

  load(props) {
    const loaded = this.state.loaded;
    this.setState({
      loading: true,
      loaded,
      error: false
    });

    if (!props.url) {
      this.fireOnLoaded = !!props.preloaded;
      this.setState({
        loading: false,
        loaded: true,
        error: false,
        errorText: null,
        errorObject: null,
        data: null
      });
      return null;
    }

    const promise = DataLoader.request(props, this.api);

    let reqId = this.reqId || 0;
    reqId += 1;
    this.reqId = reqId;

    promise
      .then((response) => {
        // If multiple requests sent, we need to
        // accept data only from last request
        if (this.reqId !== reqId) {
          return null;
        }

        if (this.props.lruCache) {
          const key = md5(this.props.urlPrefix + this.props.url + JSON.stringify(this.props.query));
          lruDataCache[key] = response;
        }

        this.fireOnLoaded = true;
        this.setState({
          loading: false,
          loaded: true,
          error: false,
          errorText: null,
          errorObject: null,
          reloadCount: this.state.reloadCount + 1,
          data: response,
          rawData: props.dataModifier && clone(response)
        }, () => {
          if (this.props.onLoad) {
            this.props.onLoad(this._getInjectingData())
          }
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          loaded: this.state.loaded,
          error: true,
          errorText: getErrorMessage(error),
          errorObject: error
        });
        if (this.props.onError) {
          this.props.onError(getErrorMessage(error));
        }
        throw new ApiError(error, { component: 'DataLoader' });
      });
    return promise;
  }

  renderItem(element, data, metadata, key = null) {
    const inject = { ...metadata };
    inject[this.props.injectPropName] = data;
    inject[`${this.props.injectPropName}ReloadCount`] = this.state.reloadCount;
    inject[`${this.props.injectPropName}Reload`] = this.reload;
    if (this.state.error) {
      inject[`${this.props.injectPropName}Error`] = this.state.errorText;
    }
    map(this.props.injectFromLoader, val => inject[val] = this.props[val]);
    inject.injectFromLoader = Object.keys(inject);

    if (!element.$$typeof && typeof element === 'function') {
      return element(inject);
    }

    return cloneElement(element, {
      key,
      ...element.props,
      ...inject
    });
  }

  renderList(element) {
    const metadata = this._getInjectingMetadata();
    return map(
      this._getInjectingData(this.state.data),
      (val, key) => this.renderItem(element, val, metadata, key));
  }

  render() {
    if (!this.props.children && !this.props.renderer) return null;

    let el = this.props.renderer;
    if (!el) {
      el =
        isArray(this.props.children) ?
          Children.only(this.props.children[0]) :
          Children.only(this.props.children);
    }

    const { loading, loaded } = this.state;

    const { renderMethod, tagSpan, className, loaderFill, silentReload, disableLoader } = this.props;

    let rv = null;

    // Render loader
    if (!disableLoader) {
      if ((!silentReload && loading) ||
        (silentReload && !loaded && loading)
      ) {
        const loader = <Loader formMainSize />;
        let classNameLoader = className ? `${className} loaderContainer` : 'loaderContainer';
        if (loaderFill) classNameLoader = `${classNameLoader} loaderContainer_fill`;
        return tagSpan ?
          <span className={classNameLoader} children={loader} /> :
          <div className={classNameLoader} children={loader} />;
      }
    }

    // Render data
    if ((this.props.renderUnloaded || loaded) && renderMethod === 'single') {
      return this.renderItem(el, this._getInjectingData(), this._getInjectingMetadata());
    }
    if ((this.props.renderUnloaded || loaded) && renderMethod === 'list') {
      rv = this.renderList(el);
    }

    return (
      tagSpan ?
        <span className={className}>{rv}</span> :
        <div className={className}>{rv}</div>
    );
  }
}
