import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

const listeners = {};

export const ALL_MODALS_CLOSE = 'allModalsClose';

export function subscribe(event, listener) {
  if (!listeners[event]) {
    listeners[event] = [];
  }
  listeners[event].push(listener);
}

export function unsubscribe(event, listener) {
  listeners[event] = filter(listeners[event], val =>
    val !== listener);
}

export function emit(event, params) {
  if (__DEV_CLI__) {
    console.info(`Global event ${event} emitted`, params); // eslint-disable-line
  }
  forEach(listeners[event], val => val(params));
}
