import { gtmHistoryChange, gtmSuccessError, gtmPush } from './handlers/gtm';

const events = {
  historyChange: {
    handlers: [gtmHistoryChange]
  },
  successError: {
    handlers: [gtmSuccessError]
  },
  push: {
    handlers: [gtmPush]
  }
};

export default events;
