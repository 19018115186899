/**
 * Workaround for accessing the store outside of the components
 * @type {null}
 */
let store = null; // eslint-disable

export function setAccessStore(newStore) {
  store = newStore;
}

export default function getStore() {
  return store;
}
