/* eslint-disable no-param-reassign */
import atob from 'atob';
import btoa from 'btoa';
import isString from 'lodash/isString';

/**
 * Simple XOR decryptor for some data providers
 */

function hexToString(hex) { // eslint-disable-line
  let string = '';
  for (let i = 0; i < hex.length; i += 2) {
    string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return string;
}

function enc(s) {
  const kl = s.charCodeAt(3) - 40;
  const ko = s.charCodeAt(4) - 40;
  const k = s.substr(ko + 5, kl);
  let newstr = '';
  const l1 = s.length;
  const l2 = k.length;
  for (let i = (ko + 5 + kl); i < l1;) {
    for (let j = 0; j < l2; i += 1, j += 1) {
      if (i < l1) {
        newstr += String.fromCharCode(s.charCodeAt(i) ^ k.charCodeAt(j)); // eslint-disable-line
      }
    }
  }
  return newstr;
}

export function xenc(s) {
  const bs = encodeURIComponent(s);
  const kl = Math.round(Math.random()*5) + 5;
  let k = '';
  for (let i = 0; i <= kl; ++i) {
    k += String.fromCharCode(Math.round(Math.random() * 75) + 47);
  }
  let news = `e${String.fromCharCode(kl + 100)}${k}`;
  let ik = 0;

  for (let i1 = 0; i1 < bs.length; ++i1) {
    news += String.fromCharCode(bs.charCodeAt(i1) ^ k.charCodeAt(ik)); // eslint-disable-line
    ik += 1;
    if (ik >= kl) ik = 0;
  }
  return news;
}

export default {
  res: (res) => {
    // if (!__PRODUCTION__) return res; // eslint-disable-line
    if (isString(res.data)) {
      const s = atob(res.data);
      if (
        s.charCodeAt(0) === 57 - 21 &&
        s.charCodeAt(1) === 104 - 5 &&
        s.charCodeAt(2) === 106 - 18
      ) {
        res.data = JSON.parse(enc(s));
      }
    }
    return res;
  }
};
