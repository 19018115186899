import axios, { CancelToken } from 'axios';
import urlJoin from 'url-join';
import urlLib from 'url';
import md5 from 'md5';
// import sha512 from 'sha512'; // sha512(str).toString('hex');
import { getErrorMessage } from 'api/api-errors';
import ApiError from 'errors/apiError';
import Notification from 'components/notification';

const httpMethods = ['get', 'post', 'put', 'delete'];

/**
 * Storage for client-side request LRU cache
 * Key - URL plus value of clientCache param,
 * value - data, request params hash and last update time for LRU
 *
 * This cache typically uses in data preloaders to avoid non-needed re-requests
 * To turn on this cache - send clientCache in api request config.
 * Value of this param also uses for cache key
 * build - if we need to cache multiple requests to one URL
 */
const clientRequestLRUCache = {};

export default class ApiClient {
  constructor(apiUrl, ...middlewares) {
    if (typeof (apiUrl) !== 'string') {
      throw new ApiError('ApiClient first param must be apiUrl:string');
    }

    this.applyMiddlewares = this.applyMiddlewares.bind(this);
    const parsedApiUrl = urlLib.parse(apiUrl);

    let prependUrlPath = parsedApiUrl.pathname;
    const maybePrependUrlHost = urlLib.format({
      protocol: parsedApiUrl.protocol,
      host: parsedApiUrl.host,
      // pathname: parsedApiUrl.pathname
    });

    // const { pathname: prependUrlPath, } = urlLib.parse(apiUrl);
    // const maybeUrlHostPort =
    const self = this;

    httpMethods.forEach(method =>
      this[method] = (url, options = {}) => {
        const {
          postData,
          sync = false,
          queryString = {},
          config: extendedConfig = {},
          cancelable,
          showErrorMessage,
          ignoreServerErrors = [],
        } = options;
        // queryString

        let config = {
          baseURL: maybePrependUrlHost,
          // url,
          // path: urlJoin(config.baseURL, config.url),
          method,
          timeout: 60000,
          withCredentials: true,
          ...extendedConfig
        };

        const processedUrl = self.applyMiddlewares(
          middlewares,
          'url',
          url,
          options
        );

        const { pathname, query = {} } = urlLib.parse(urlJoin(prependUrlPath, processedUrl), true);
        // parsedUrl.

        const fullUrlPath = urlLib.format({
          pathname,
          query: { ...query, ...queryString }
        });

        config.url = fullUrlPath;
        if (sync) return undefined;

        // `params` are the URL parameters to be sent with the request
        // Must be a plain object or a URLSearchParams object
        // if (queryString) config.params = queryString;

        if (postData) config.data = postData;

        // Apply Middlewares req function
        config = self.applyMiddlewares(middlewares, 'req', config, options);
        let cancel = null;
        if (cancelable) {
          config.cancelToken = new CancelToken((c) => {
            // Workaround for dev-cli mode. Request cancel crashes system in dev-cli mode
            if (__DEV_CLI__) {
              // eslint-disable-next-line
              console.warn('Request cancellation is not supported in dev-cli mode!');
              cancel = () => null;
            } else {
              cancel = c;
            }
          });
        }

        // Client-side request cache
        let clientCacheHash = null;
        let clientCacheKey = null;
        if (__CLIENT__ && options.clientCache) {
          clientCacheKey = `${processedUrl}-${options.clientCache}`;

          clientCacheHash = md5(JSON.stringify(options));
          if (clientRequestLRUCache[clientCacheKey] &&
            clientRequestLRUCache[clientCacheKey].hash === clientCacheHash) {
            return Promise.resolve(clientRequestLRUCache[clientCacheKey].payload);
          }
        }

        if (__DEV_CLI__) {
          // eslint-disable-next-line no-console
          console.warn(`Api Client requested ${method.toUpperCase()}::${fullUrlPath}`);
        }

        const apiCall = axios
          .request(config)
          .then(apiResult => self.applyMiddlewares(middlewares, 'res', apiResult, options))
          .then(({ data } = {}) => {
            // console.log('678', JSON.stringify(data));
            const { status, payload } = data;

            if (status === 'error' && ignoreServerErrors.indexOf(data.class) === -1) {
              return Promise.reject({
                response: {
                  httpCode: 200,
                  httpStatusText: 'Ok',
                  data
                }
              });
            }

            if (status === 'error') {
              Object.defineProperty(payload, '$error', { value: data.error });
            }

            if (data.ttl && typeof payload === 'object') {
              Object.defineProperty(payload, '$ttl', { value: data.ttl });
            }


            // Put data to client hash if needed
            if (clientCacheHash) {
              clientRequestLRUCache[clientCacheKey] = {
                payload,
                hash: clientCacheHash,
                timeoutId: setTimeout(
                  () => delete clientRequestLRUCache[clientCacheKey],
                  60000 * 20
                )
              };
            }

            return payload;
          })
          .catch((apiError) => {
            if (showErrorMessage) {
              Notification.error(getErrorMessage(apiError));
            }
            if (axios.isCancel(apiError)) {
              // eslint-disable-next-line
              console.warn('Request cancelled');
              return '$cancelled';
            }
            const newApiErr = self.applyMiddlewares(middlewares, 'err', apiError, options);
            return Promise.reject(newApiErr);
          });

        if (cancelable) return { cancel, promise: apiCall };
        return apiCall;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  awaitInit() {
    // TODO прописать инициализацию
    /*
     Инициализация нужна чтоб получить новую сесияю от бекенда... если старая устарела,
     и с ней работать, или со старой сесией, а потом прокинуть клиенту.
     */
    this.initState = this.initState || this.get('init');
    return this.initState;
    // return Promise.resolve();
  }

  applyMiddlewares(middlewares, type, configurable, params) {
    if (middlewares.length === 0) return configurable;

    const [{ [type]: fn }, ...rest] = middlewares;
    let configured = configurable;
    if (fn) configured = fn.call(this, configurable, params);
    if (!configured) configured = configurable;

    return this.applyMiddlewares(rest, type, configured, params);
  }
}

// console.log('Config', config);
// request.end((apiError, apiResult = {}) => {
/*
 * Apply Middlewares err function
 * and return rejected request
 */
/* if (apiError) {
 middlewares.forEach(({ err }) => err && err(apiError, apiResult));
 const { body, header: { expires } = {} } = apiResult;
 // TODO: Rewrite this
 return reject({
 payload: body || apiError,
 response: apiResult,
 expires: new Date(expires).getTime()
 });
 } */

/*
 * Apply Middlewares res function
 * and return resolved request
 */
/* middlewares.forEach(({ res }) => res && res(apiResult));

 const { body, header: { expires } = {} } = apiResult;
 Object.defineProperty(body, 'expires', { value: new Date(expires).getTime() });
 // request.expires
 return resolve(body); */


//   awaitAllRequests() {
//     return Promise.all(this.pendingPromises);
// return Promise.resolve();
//  }

/*
 Currently the retrying logic checks for:

 ECONNRESET
 ETIMEDOUT
 EADDRINFO
 ESOCKETTIMEDOUT
 superagent client timeouts
 bad gateway errors (502, 503, 504 statuses)
 Internal Server Error (500 status)
 */
