import update from 'react-addons-update';

const TOGGLE_PHONE_SEARCH_ON_MAP = 'togglePhoneSearchOnMap';

const initialState = {
  action: null,
  showPhoneSearchOnMap: false,
};

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {
    case TOGGLE_PHONE_SEARCH_ON_MAP:
      return update(state, {
        ...state,
        action: { $set: action.type },
        showPhoneSearchOnMap: { $set: action.visibility }
      });

    default:
      return state;
  }
}

export function togglePhoneSearchOnMap(visibility) {
  return {
    type: TOGGLE_PHONE_SEARCH_ON_MAP, visibility
  };
}

