/* eslint-disable react/no-multi-comp */
import React, { Children, Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import extend from 'extend';
import getDisplayName from 'react-display-name';
import HttpStatus from 'exceptions/httpStatus';
import { emit } from 'helpers/global-events';

import ProjectConfig from '../../config';

const langs = {
  ua: 'UA'
};

const langIsoMap = {
  uk: 'ua'
};

const langIsoMapBack = {
  ru: 'ru',
  ua: 'uk'
};

const langInfo = {
  ru: {
    nameShort: 'RU',
    nameLong: 'На русском',
    isoCode: 'ru'
  },
  ua: {
    nameShort: 'UK',
    nameLong: 'Українською',
    isoCode: 'uk'
  },
};

// const LangContext = React.createContext(ProjectConfig.locale);

class LangContext extends Component {
  static propTypes = {
    activeRoute: PropTypes.shape({ lang: PropTypes.string.isRequired }),
    children: PropTypes.node
  };

  static childContextTypes = {
    lang: PropTypes.string
  };

  getChildContext() {
    return { lang: this.props.activeRoute.lang || ProjectConfig.locale };
  }

  componentDidUpdate(prevProps) {
    const { activeRoute: activeRouteNew } = this.props;
    const { activeRoute: activeRouteOld } = prevProps;

    if (
      activeRouteNew.component === activeRouteOld.component &&
      activeRouteNew.lang !== activeRouteOld.lang
    ) {
      emit('gLangChanged', {});
    }
  }

  render() {
    return Children.only(this.props.children);
  }
}

function I18nHoc(translates) {
  return function wrapComponent(WrapComponent) {
    return class ComponentWithTranslate extends WrapComponent {
      static displayName = `i18n(${getDisplayName(WrapComponent)})`;
      static contextTypes = extend(WrapComponent.contextTypes || {}, {
        lang: PropTypes.string
      });

      constructor(props, context) {
        super(props, context);
        const { routeParams } = props;
        if (routeParams && routeParams.lang) {
          if (!langs[routeParams.lang]) {
            throw new HttpStatus(404);
          }
        }
      }

      i18n = (phraseId, placeholders = {}) => {
        const trans = translates ? translates[phraseId] : null;
        const lang = this.context.lang || ProjectConfig.locale;
        return trans ? parsePlaceholders(trans[lang], placeholders) : phraseId;
      }

      i18nGetLang = () => {
        return this.context.lang || ProjectConfig.locale;
      }
    };
  };
}

function I18nPropHoc(translates) {
  return function wrapComponent(WrapComponent) {
    return class ComponentWithTranslateProp extends WrapComponent {
      static displayName = `i18nProp(${getDisplayName(WrapComponent)})`;
      static contextTypes = extend(WrapComponent.contextTypes || {}, {
        lang: PropTypes.string
      });

      render() {
        const lang = this.context.lang || ProjectConfig.locale;
        const inject = {
          lang,
          nonDefaultLang: lang === ProjectConfig.locale ? '' : lang
        };

        return <WrapComponent {...this.props} {...inject} />;
      }
    };
  };
}

class Trans extends PureComponent {
  static propTypes = {
    lang: PropTypes.string,
    children: PropTypes.node
  };

  static contextTypes = extend(Component.contextTypes || {}, {
    lang: PropTypes.string
  });

  render() {
    const lang = this.context.lang || ProjectConfig.locale;

    if (lang !== this.props.lang) return null;

    return this.props.children;
  }
}

function parsePlaceholders(str, ph) {
  let newStr = str;
  Object.keys(ph).forEach((key) => {
    newStr = newStr.replace(`{{${key}}}`, ph[key]);
  });

  return newStr;
}

function langFromUrl(url) {
  // return 'ua';
  const lMatch = Object.keys(langIsoMap).join('|');

  if (!lMatch) return ProjectConfig.locale;

  const res = url.match(new RegExp(`/(${lMatch})(\/.*|\\?|#|$)`));

  if (res && res[1]) return langIsoMap[res[1]];

  return ProjectConfig.locale;
}

export {
  langs,
  langIsoMap,
  langIsoMapBack,
  langInfo,
  LangContext,
  I18nHoc,
  I18nPropHoc,
  Trans,
  langFromUrl
};
