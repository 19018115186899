/**
 * Created by Vit on 02.12.2017.
 */
/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import each from 'lodash/each';
// import getVariable from 'helpers/scss-variable-loader';

import 'styles/base/components/row/_row.scss';

const classes = new Bem('row');
const classesCol = new Bem('col');
const classesTable = new Bem('table');
// const paddingRightMain = getVariable('paddingRightMain');

/**
 * Validate width prop (md, mt, mp).
 * Should be a number or string = "auto".
 *
 * @param props
 * @param propName
 * @param componentName
 */
const propWidthValidation = (props, propName, componentName) => {
  const property = props[propName];
  if (property !== undefined && !isNumber(property) && property !== 'auto') {
    return new Error(
      `Prop *${propName}* in ${componentName} should be a Number or "auto".
Range from 1 till 12 is responsible for columns number to be filled.
0 - display: none.
`
    );
  }
  return null;
};

/**
 * Validate custom width prop. Number.
 * Range till 100 -  fixed width in %.
 * Greater than || equals 100 - fixed width in px.
 *
 * @param props
 * @param propName
 * @param componentName
 */
const propCustomWidthValidation = (props, propName, componentName) => {
  const property = props[propName];
  if (property !== undefined && !isNumber(property)) {
    return new Error(
      `Prop *${propName}* in ${componentName} should be a Number.
Range till 100 -  fixed width in %.
Greater than 100 - fixed width in px.
`
    );
  }
  return null;
};

/**
 *
 * = Layout component =
 * Default Col in Row evenly distributed.
 *
 * === Example ===
 * <Row>
 *   <Col md={6} mt={5} mp={12} allPr={20} allPl={35}>
 *     Col content
 *   </Col>
 *   <Col auto mdPr={10} mtPr={20} mpPr={0} allPl={15}>
 *     Col content
 *   </Col>
 *   <Col md="auto" mt={8}>
 *     Col content
 *   </Col>
 * </Row>
 * <Row>
 *   <Col mdCw={360} mtCw={800} mpCw={40}>
 *     Col content
 *   </Col>
 *   <Col mdAdjust={360} mtAdjust={800} mpAdjust={40}>
 *     Col content
 *   </Col>
 * </Row>
 *
 */

class Row extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape({}),
    children: PropTypes.node,
    wRef: PropTypes.func,

    // props for SEO
    seoProps: PropTypes.shape({}),

    // Отменяет ограничение максимальной ширины:
    noMaxW: PropTypes.bool,

    // Увеличивает высоту Row на всю допустимую высоту flex-grow: 1:
    hFull: PropTypes.bool,

    // Переносит внутренные блоки если они не помещаются:
    allAuto: PropTypes.bool,

    // Центрирует по горизонтали:
    alignIcenter: PropTypes.bool,

    // justify-content: flex-end for:
    //  - ALL devices
    allJustifyCFlexEnd: PropTypes.bool,
    //  - desktop
    mdJustifyCFlexEnd: PropTypes.bool,
    //  - tablet
    mtJustifyCFlexEnd: PropTypes.bool,
    //  - phone
    mpJustifyCFlexEnd: PropTypes.bool,

    // justify-content: center for:
    //  - ALL devices
    allJustifyCCenter: PropTypes.bool,
    //  - desktop
    mdJustifyCCenter: PropTypes.bool,
    //  - tablet
    mtJustifyCCenter: PropTypes.bool,
    //  - phone
    mpJustifyCCenter: PropTypes.bool,

    // Переопределение флексов на колонку:
    //  - ALL devices
    allColumn: PropTypes.bool,
    //  - desktop
    mdColumn: PropTypes.bool,
    //  - tablet
    mtColumn: PropTypes.bool,
    //  - phone
    mpColumn: PropTypes.bool
  };

  setModifiers() {
    const {
      noMaxW, hFull, allAuto, alignIcenter,
      allJustifyCFlexEnd, mdJustifyCFlexEnd, mtJustifyCFlexEnd, mpJustifyCFlexEnd,
      allJustifyCCenter, mdJustifyCCenter, mtJustifyCCenter, mpJustifyCCenter,
      allColumn, mdColumn, mtColumn, mpColumn
    } = this.props;

    return {
      no_max_w: noMaxW,
      h_full: hFull,
      all_auto: allAuto,
      align_i_center: alignIcenter,
      all_justify_c_flex_end: allJustifyCFlexEnd,
      md_justify_c_flex_end: !allJustifyCFlexEnd && mdJustifyCFlexEnd,
      mt_justify_c_flex_end: !allJustifyCFlexEnd && mtJustifyCFlexEnd,
      mp_justify_c_flex_end: !allJustifyCFlexEnd && mpJustifyCFlexEnd,
      all_justify_c_center: allJustifyCCenter,
      md_justify_c_center: !allJustifyCCenter && mdJustifyCCenter,
      mt_justify_c_center: !allJustifyCCenter && mtJustifyCCenter,
      mp_justify_c_center: !allJustifyCCenter && mpJustifyCCenter,
      all_column: allColumn,
      md_column: !allColumn && mdColumn,
      mt_column: !allColumn && mtColumn,
      mp_column: !allColumn && mpColumn,
    };
  }

  render() {
    const {
      className, style, children, seoProps = {}, wRef,
      allAuto, allColumn, mdColumn, mtColumn, mpColumn
    } = this.props;

    return (
      <div
        {...classes(null, this.setModifiers(), className)}
        ref={(node) => {
          if (wRef) wRef(node);
        }}
        children={Children.map(children, (el) => {
          if (el) {
            return (cloneElement(el, {
              allAuto: el.props.allAuto || allAuto,
              allColumn: el.props.allColumn || allColumn,
              mdColumn: el.props.mdColumn || mdColumn,
              mtColumn: el.props.mtColumn || mtColumn,
              mpColumn: el.props.mpColumn || mpColumn
            }));
          }
          return el;
        })}
        {...seoProps}
        style={style}
      />
    );
  }
}

class Col extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // props for SEO
    // seoProps: PropTypes.shape({}),

    // Убрать все паддинги
    paddingNo: PropTypes.bool,

    // Ровняет по левому краю justify-content: flex-start:
    //  - ALL devices
    allJustifyCFlexStart: PropTypes.bool,
    //  - desktop
    mdJustifyCFlexStart: PropTypes.bool,
    //  - tablet
    mtJustifyCFlexStart: PropTypes.bool,
    //  - phone
    mpJustifyCFlexStart: PropTypes.bool,

    // Ровняет по правому краю justify-content: flex-end:
    //  - ALL devices
    allJustifyCFlexEnd: PropTypes.bool,
    //  - desktop
    mdJustifyCFlexEnd: PropTypes.bool,
    //  - tablet
    mtJustifyCFlexEnd: PropTypes.bool,
    //  - phone
    mpJustifyCFlexEnd: PropTypes.bool,

    // Центрирует по горизонтали justify-content: center:
    //  - ALL devices
    allJustifyCCenter: PropTypes.bool,
    //  - desktop
    mdJustifyCCenter: PropTypes.bool,
    //  - tablet
    mtJustifyCCenter: PropTypes.bool,
    //  - phone
    mpJustifyCCenter: PropTypes.bool,

    // align-self: stretch for:
    //  - ALL devices
    allAlignSStretch: PropTypes.bool,
    //  - desktop
    mdAlignSStretch: PropTypes.bool,
    //  - tablet
    mtAlignSStretch: PropTypes.bool,
    //  - phone
    mpAlignSStretch: PropTypes.bool,

    // align-self: flex-end for:
    //  - ALL devices
    allAlignSEnd: PropTypes.bool,
    //  - desktop
    mdAlignSEnd: PropTypes.bool,
    //  - tablet
    mtAlignSEnd: PropTypes.bool,
    //  - phone
    mpAlignSEnd: PropTypes.bool,

    // align-item: center for:
    //  - ALL devices
    allAlignICenter: PropTypes.bool,
    //  - desktop
    mdAlignICenter: PropTypes.bool,
    //  - tablet
    mtAlignICenter: PropTypes.bool,
    //  - phone
    mpAlignICenter: PropTypes.bool,

    // align-item: flex-end for:
    //  - ALL devices
    allAlignIEnd: PropTypes.bool,
    //  - desktop
    mdAlignIEnd: PropTypes.bool,
    //  - tablet
    mtAlignIEnd: PropTypes.bool,
    //  - phone
    mpAlignIEnd: PropTypes.bool,

    // order for:
    //  - ALL devices
    allOrder: PropTypes.number,
    //  - desktop
    mdOrder: PropTypes.number,
    //  - tablet
    mtOrder: PropTypes.number,
    //  - phone
    mpOrder: PropTypes.number,

    // display: flex for:
    //  - ALL devices
    allFlex: PropTypes.bool,
    //  - desktop
    mdFlex: PropTypes.bool,
    //  - tablet
    mtFlex: PropTypes.bool,
    //  - phone
    mpFlex: PropTypes.bool,

    // Padding right in px for:
    //  - ALL devices
    allPr: PropTypes.number,
    //  - desktop
    mdPr: PropTypes.number,
    //  - tablet
    mtPr: PropTypes.number,
    //  - phone
    mpPr: PropTypes.number,

    // Padding left in px for:
    //  - ALL devices
    allPl: PropTypes.number,
    //  - desktop
    mdPl: PropTypes.number,
    //  - tablet
    mtPl: PropTypes.number,
    //  - phone
    mpPl: PropTypes.number,

    // Padding top in px for:
    //  - ALL devices
    allPt: PropTypes.number,
    //  - desktop
    mdPt: PropTypes.number,
    //  - tablet
    mtPt: PropTypes.number,
    //  - phone
    mpPt: PropTypes.number,

    // Padding bottom in px for:
    //  - ALL devices
    allPb: PropTypes.number,
    //  - desktop
    mdPb: PropTypes.number,
    //  - tablet
    mtPb: PropTypes.number,
    //  - phone
    mpPb: PropTypes.number,

    // Ширина колонки по ее контенту:
    //  - всех разрешений
    allAuto: PropTypes.bool,
    //  - desktop
    mdAuto: PropTypes.bool,
    //  - tablet
    mtAuto: PropTypes.bool,
    //  - phone
    mpAuto: PropTypes.bool,

    // Четко заданная (кастомная) ширина в px/% для:
    //  - всех разрешений
    allCw: propCustomWidthValidation,
    //  - desktop
    mdCw: propCustomWidthValidation,
    //  - tablet
    mtCw: propCustomWidthValidation,
    //  - phone
    mpCw: propCustomWidthValidation,

    // Корректировка остальных колонок на заданную (кастомную) ширину в px/% для:
    //  - всех разрешений
    allAdjust: propCustomWidthValidation,
    //  - desktop
    mdAdjust: propCustomWidthValidation,
    //  - tablet
    mtAdjust: propCustomWidthValidation,
    //  - phone
    mpAdjust: propCustomWidthValidation,

    // Четко заданная (кастомная) МАКСИМАЛЬНАЯ ширина в px/% для:
    //  - всех разрешений
    allCmw: propCustomWidthValidation,
    //  - desktop
    mdCmw: propCustomWidthValidation,
    //  - tablet
    mtCmw: propCustomWidthValidation,
    //  - phone
    mpCmw: propCustomWidthValidation,

    // Ширина Col в количестве колонок 1-12 или по контенту "auto":
    //  - desktop
    md: propWidthValidation,
    //  - tablet
    mt: propWidthValidation,
    //  - phone
    mp: propWidthValidation,

    // Переопределение флексов на колонку:
    //  - ALL devices
    allColumn: PropTypes.bool,
    //  - desktop
    mdColumn: PropTypes.bool,
    //  - tablet
    mtColumn: PropTypes.bool,
    //  - phone
    mpColumn: PropTypes.bool,

    // inline styles for Col (optional and only for adminka)
    style: PropTypes.shape({})
  };

  static defaultProps = {
    allPr: 50 // paddingRightMain
  };

  setModifiers() {
    const {
      allColumn, mdColumn, mtColumn, mpColumn,
      paddingNo,
      allAlignSStretch, allAlignSEnd, allAlignICenter, allAlignIEnd,
      allJustifyCFlexStart, allJustifyCFlexEnd, allJustifyCCenter,
      allOrder, allFlex, allAuto, allPr, allPl, allPt, allPb,
      mdAlignSStretch, mtAlignSStretch, mpAlignSStretch,
      mdAlignSEnd, mtAlignSEnd, mpAlignSEnd,
      mdAlignICenter, mtAlignICenter, mpAlignICenter,
      mdAlignIEnd, mtAlignIEnd, mpAlignIEnd,
      mdJustifyCFlexStart, mtJustifyCFlexStart, mpJustifyCFlexStart,
      mdJustifyCFlexEnd, mtJustifyCFlexEnd, mpJustifyCFlexEnd,
      mdJustifyCCenter, mtJustifyCCenter, mpJustifyCCenter,
      mdOrder, mtOrder, mpOrder,
      mdFlex, mtFlex, mpFlex,
      mdAuto, mtAuto, mpAuto,
      mdPr, mtPr, mpPr,
      mdPl, mtPl, mpPl,
      mdPt, mtPt, mpPt,
      mdPb, mtPb, mpPb
    } = this.props;
    const isMdPr = mdPr >= 0;
    const isMtPr = mtPr >= 0;
    const isMpPr = mpPr >= 0;

    return {
      paddingNo,
      all_column: allColumn,
      md_column: !allColumn && mdColumn,
      mt_column: !allColumn && mtColumn,
      mp_column: !allColumn && mpColumn,
      all_flex: allFlex,
      md_flex: !allFlex && mdFlex,
      mt_flex: !allFlex && mtFlex,
      mp_flex: !allFlex && mpFlex,
      all_auto: allAuto,
      md_auto: !allAuto && mdAuto,
      mt_auto: !allAuto && mtAuto,
      mp_auto: !allAuto && mpAuto,
      all_align_s_stretch: allAlignSStretch,
      md_align_s_stretch: !allAlignSStretch && mdAlignSStretch,
      mt_align_s_stretch: !allAlignSStretch && mtAlignSStretch,
      mp_align_s_stretch: !allAlignSStretch && mpAlignSStretch,
      all_align_s_flex_end: allAlignSEnd,
      md_align_s_flex_end: !allAlignSEnd && mdAlignSEnd,
      mt_align_s_flex_end: !allAlignSEnd && mtAlignSEnd,
      mp_align_s_flex_end: !allAlignSEnd && mpAlignSEnd,
      all_align_i_center: allAlignICenter,
      md_align_i_center: !allAlignICenter && mdAlignICenter,
      mt_align_i_center: !allAlignICenter && mtAlignICenter,
      mp_align_i_center: !allAlignICenter && mpAlignICenter,
      all_align_i_flex_end: allAlignIEnd,
      md_align_i_flex_end: !allAlignIEnd && mdAlignIEnd,
      mt_align_i_flex_end: !allAlignIEnd && mtAlignIEnd,
      mp_align_i_flex_end: !allAlignIEnd && mpAlignIEnd,
      all_justify_c_flex_start: allJustifyCFlexStart,
      md_justify_c_flex_start: !allJustifyCFlexStart && mdJustifyCFlexStart,
      mt_justify_c_flex_start: !allJustifyCFlexStart && mtJustifyCFlexStart,
      mp_justify_c_flex_start: !allJustifyCFlexStart && mpJustifyCFlexStart,
      all_justify_c_flex_end: allJustifyCFlexEnd,
      md_justify_c_flex_end: !allJustifyCFlexEnd && mdJustifyCFlexEnd,
      mt_justify_c_flex_end: !allJustifyCFlexEnd && mtJustifyCFlexEnd,
      mp_justify_c_flex_end: !allJustifyCFlexEnd && mpJustifyCFlexEnd,
      all_justify_c_center: allJustifyCCenter,
      md_justify_c_center: !allJustifyCCenter && mdJustifyCCenter,
      mt_justify_c_center: !allJustifyCCenter && mtJustifyCCenter,
      mp_justify_c_center: !allJustifyCCenter && mpJustifyCCenter,
      [`all_order_${allOrder}`]: allOrder,
      [`md_order_${mdOrder}`]: !allOrder && mdOrder,
      [`mt_order_${mtOrder}`]: !allOrder && mtOrder,
      [`mp_order_${mpOrder}`]: !allOrder && mpOrder,
      [`all_pr_${allPr}`]: !(isMdPr || isMtPr || isMpPr) && allPr,
      [`md_pr_${mdPr}`]: isMdPr,
      [`mt_pr_${mtPr}`]: isMtPr,
      [`mp_pr_${mpPr}`]: isMpPr,
      [`all_pl_${allPl}`]: allPl,
      [`md_pl_${mdPl}`]: allPl === undefined && mdPl >= 0,
      [`mt_pl_${mtPl}`]: allPl === undefined && mtPl >= 0,
      [`mp_pl_${mpPl}`]: allPl === undefined && mpPl >= 0,
      [`all_pt_${allPt}`]: allPt,
      [`md_pt_${mdPt}`]: allPt === undefined && mdPt >= 0,
      [`mt_pt_${mtPt}`]: allPt === undefined && mtPt >= 0,
      [`mp_pt_${mpPt}`]: allPt === undefined && mpPt >= 0,
      [`all_pb_${allPb}`]: allPb,
      [`md_pb_${mdPb}`]: allPb === undefined && mdPb >= 0,
      [`mt_pb_${mtPb}`]: allPb === undefined && mtPb >= 0,
      [`mp_pb_${mpPb}`]: allPb === undefined && mpPb >= 0
    };
  }

  getModsNames() {
    const {
      allCw, mdCw, mtCw, mpCw,
      allAdjust, mdAdjust, mtAdjust, mpAdjust,
      allCmw, mdCmw, mtCmw, mpCmw,
      md, mt, mp
    } = this.props;
    if (allAdjust) return { [`all_adjust_${allAdjust}`]: true };
    if (allCw) return { [`all_cw_${allCw}`]: true };
    if (allCmw) return { [`all_cmw_${allCmw}`]: true };
    return {
      [`md_${md}`]: !mdCw && (md >= 0 || md),
      [`mt_${mt}`]: !mtCw && (mt >= 0 || mt),
      [`mp_${mp}`]: !mpCw && (mp >= 0 || mp),
      [`md_cw_${mdCw}`]: mdCw,
      [`mt_cw_${mtCw}`]: mtCw,
      [`mp_cw_${mpCw}`]: mpCw,
      [`md_adjust_${mdAdjust}`]: !mdCw && mdAdjust,
      [`mt_adjust_${mtAdjust}`]: !mtCw && mtAdjust,
      [`mp_adjust_${mpAdjust}`]: !mpCw && mpAdjust,
      [`md_cmw_${mdCmw}`]: mdCmw,
      [`mt_cmw_${mtCmw}`]: mtCmw,
      [`mp_cmw_${mpCmw}`]: mpCmw,
    };
  }

  render() {
    const { className, children, style /* , seoProps = {} */ } = this.props;

    const mods = {
      ...this.setModifiers(),
      ...this.getModsNames()
    };

    return (
      <div
        {...classesCol(null, mods, className)}
        // {...seoProps}
        style={style}
        children={children}
      />
    );
  }
}

const dataViewType = [
  'tableInfoMain',
  'tableInfoObject',
  'tableInfoHouse',
  'tableInfoMainTopicWider',
  // 'tableInfoTile',
  // 'tableLined',
  // 'tablePageCompareContainer',
  // 'tablePageCompare',
  'tablePageSeo'
];

class Table extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    dataView: PropTypes.oneOfType([
      PropTypes.oneOf(dataViewType),
      PropTypes.arrayOf(PropTypes.oneOf(dataViewType))
    ]),

    // inline styles for Table (optional and only for adminka)
    style: PropTypes.shape({})
  };

  render() {
    const {
      className, children, dataView, style
    } = this.props;
    const mods = {};

    if (dataView) {
      const dataViewIsArray = isArray(dataView);
      if (dataViewIsArray && dataView.length) {
        each(dataView, view => mods[view] = true);
      } else if (!dataViewIsArray) {
        mods[dataView] = true;
      }
    }

    return (
      <div
        {...classesTable(null, mods, className)}
        style={style}
        children={children}
      />
    );
  }
}

export {
  Row as default,
  Col,
  Table
};
