import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Icon, { iconTypes } from 'components/icon';
import Link from 'components/link';

import translates from './not-found-i18n.json';

const bemClasses = new Bem('pageNotFound');

@I18nHoc(translates)
export default class ContentBild extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { i18n } = this;

    return (
      <div {...bemClasses({ extra: this.props.className })}>
        <div {...bemClasses('iconContainer')}>
          <Icon {...bemClasses('icon', 'catNotFound')} type={iconTypes.catNotFound} />
        </div>
        <div {...bemClasses('topicContainer')}>
          <div {...bemClasses('text', 'h1')}><h1 children={i18n('bildError404')} /></div>
        </div>
        <div {...bemClasses('descriptionContainer')}>
          <span {...bemClasses('text', 'description')}><p children={i18n('bildError404Description')} /></span>
        </div>
        <div {...bemClasses('linkContainer')}>
          <Link
            {...bemClasses('link')}
            href="/"
            title={i18n('bildToMain')}
            blank
          >
            <span {...bemClasses('linkContent')}>
              <span {...bemClasses('linkShadow')} />
              <span {...bemClasses('text', 'link')} children={i18n('bildToMain')} />
            </span>
          </Link>
        </div>
      </div>
    );
  }
}
