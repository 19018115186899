const dataViews = {
  subscribe: 'subscribe', // вид кнопки для компонента subscribe
  favorites: 'favorites', // вид кнопки для компонента favorites
  favoritesBild: 'favoritesBild', // вид кнопки для компонента favorites на bild.ua
  unitControl: 'unitControl', // вид кнопки для компонента "Упраление блоком" на bild.ua
  share: 'share', // вид кнопки для компонента share
  postControl: 'postControl', // вид кнопок для компонента postControl
  float: 'float', // круглая кнопка, которая должна быть прибита к правому нижнему углу и скролиться с контентом
  adminAdd: 'adminAdd', // кнопка '+' (используется в Админке, чтоб не писать доп стили)
  adminHelp: 'adminHelp', // кнопка '?' (используется в Админке, чтоб не писать доп стили)
  adminFilter: 'adminFilter', // кнопка фильтрации (используется в Админке, чтоб не писать доп стили)
  persons: 'persons', // вид кнопки для вызова модалки с списком пользователей
  moreLess: 'moreLess' // вид кнопки для 'Показать больше/меньше'
};

const btnThemes = {
  main: 'main', // основной цвет кнопки
  tel: 'tel', // внешний вид кнопки телефона
  red: 'red', // красная кнопка
  white: 'white', // белая кнопка
  light: 'light', // светлая кнопка, серый текст
  lightColor: 'lightColor', // светлая кнопка, цветной текст
  olive: 'olive', // зеленая кнопка
  sky: 'sky' // голубая кнопка
};

export {
  dataViews,
  btnThemes
};
