import loadable from '@loadable/component';

const landingRoutes = {
  developer: {
    component: loadable(() => import(/* webpackChunkName: "developer" */ './section-developer')),
    mods: 'pageFill',
    content: 'developer'
  },
  developers: {
    component: loadable(() => import(/* webpackChunkName: "developers" */ './section-developers')),
    content: 'developer',
    pagesAccept: true
  },
  project: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'project',
    noLowerMenu: true
  },
  objects: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'objects',
    pagesAccept: true,
    noLowerMenu: true
  },
  object: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'object',
    noLowerMenu: true
  },
  complexComments: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'comments',
    pagesAccept: false,
    noLowerMenu: true
  },
  complexTerms: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'terms',
    pagesAccept: false,
    noLowerMenu: true
  },
  complexNews: {
    component: loadable(() => import(/* webpackChunkName: "project" */ './section-project')),
    mods: 'pageFill',
    content: 'complexNews',
    pagesAccept: true
  },
  projects: {
    component: loadable(() => import(/* webpackChunkName: "projects" */ './section-projects')),
    mods: 'pageFill pageSearch',
    pagesAccept: true,
    noHeaderSearch: true
  },
  projectsStocks: {
    component: loadable(() => import(/* webpackChunkName: "stocks" */ './section-stocks')),
    content: 'stock',
    pagesAccept: true,
  },
  projectsOnMap: {
    component: loadable(() => import(/* webpackChunkName: "proj-on-map" */ './section-projects-on-map')),
    mods: 'pageFill pageFixed',
    pagesAccept: false,
    noFooter: true,
    noHeaderBanner: true,
    noHeaderSearch: true,
    mainSearch: true
  },
};

export default landingRoutes;
