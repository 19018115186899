/**
 * Created by sem on 26.10.16.
 */

import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import extend from 'extend';
import ApiClient from './api-client';

function factory() {
  return function wrapComponent(Component) {
    return class ComponentWithApi extends Component {
      static displayName = `api(${getDisplayName(Component)})`;
      // static displayName = `Fuck`;

      static contextTypes = extend(Component.contextTypes || {}, {
        api: PropTypes.instanceOf(ApiClient)
      });

      constructor(props, context) {
        super(props, context);
        this.api = context.api;
      }
    };
  };
}

export default factory();
export { factory };
