/**
 * Created by PIV on 22.06.2016.
 */
/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import 'styles/base/components/block/_block.scss';

const classes = new Bem('block');

/**
 * === Block Component ===
 *
 * Основной компонент сайта, у него есть шапка и подвал, контент внутри.
 * Использется для однообразия показа контента.
 * На мобилах шапка прилипает.
 *
 * = Example =
 * <Block>
 *   <BlockHeader
 *     headerTopic={header topic}
 *   />
 *   <BlockContent>{content}</BlockContent>
 *   <BlockFooter>
 *      {footer}
 *   </BlockFooter>
 * </Block>
 */

export default class Block extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // Скрывает избыток информации, тянется по родителю или жестко задан
    overflowHidden: PropTypes.bool,

    // Говорит о том, что в Блоке есть sticky
    hasStickyContentTabs: PropTypes.bool,

    // Блок тянется по всей возможной высоте (используется в основном для админки)
    hFull: PropTypes.bool,

    // Блок тянется по всей возможной ширине
    wFull: PropTypes.bool,

    // margin-bottom: 0 в Block (используется пока только в Форуме)
    noMarginB: PropTypes.bool,

    // Убирается background и padding в Block и BlockContent (используется пока только в Форуме)
    noPdBgBsh: PropTypes.bool,

    // Props для обхода использования findDOMNode в месте использования компонента Block
    blockRef: PropTypes.func
  };

  render() {
    const {
      children, className,
      overflowHidden, hasStickyContentTabs,
      hFull, wFull, noMarginB, noPdBgBsh,
      blockRef
    } = this.props;
    const mods = {
      overflowHidden, hasStickyContentTabs, noMarginB, noPdBgBsh, hFull, wFull
    };

    return (
      <div {...classes(null, mods, className)} ref={blockRef}>
        {children}
      </div>
    );
  }
}

export class BlockHeader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // Кастомное наполнение хедера
    children: PropTypes.node,

    // Главный топик в хедере
    headerTopic: PropTypes.any, // eslint-disable-line

    // Компонент/текст справа от главного топика
    headerTopicAfter: PropTypes.any, // eslint-disable-line

    // Компонент слева в шапке
    headerLeft: PropTypes.any, // eslint-disable-line

    // Компонент/текст справа в шапке
    headerRight: PropTypes.any, // eslint-disable-line

    // Табы (опционально)
    headerTabs: PropTypes.any, // eslint-disable-line

    // Добавляет background и box-shadow в BlockHeader (используется пока только в Форуме)
    addBgBsh: PropTypes.bool,

    // Добавляет padding-bottom в BlockHeader (используется пока только в Форуме)
    addPdBottom: PropTypes.bool,

    // Топик в хедере становится одну строку и если не помещается - троеточится
    headerTopicTextNowrap: PropTypes.bool
  };

  renderChildren() {
    if (this.props.children) return this.props.children;

    const {
      headerTopic, headerTopicAfter, headerLeft, headerRight, headerTabs,
      headerTopicTextNowrap
    } = this.props;

    if (headerLeft) {
      return (
        <React.Fragment>
          <div {...classes('headerContent')}>
            <div {...classes('headerLeft')} children={headerLeft} />
            <div {...classes('headerRight')} children={headerRight} />
          </div>
          <div {...classes('headerTabs')} children={headerTabs} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div {...classes('headerContent')} data-test="headerContent">
          <div {...classes('headerLeft')}>
            <div {...classes('headerTopic')}>
              <div {...classes('headerTopicLeft', { nowrap: headerTopicTextNowrap })}>
                <div {...classes('text')}>
                  {headerTopic}
                </div>
              </div>
              <div {...classes('headerTopicRight')} children={headerTopicAfter} />
            </div>
          </div>
          <div {...classes('headerRight')} children={headerRight} />
        </div>
        <div
          {...classes('headerTabs')}
          children={headerTabs}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      className, addBgBsh, addPdBottom
    } = this.props;

    return (
      <div {...classes('header', { addBgBsh, addPdBottom }, className)} children={this.renderChildren()} />
    );
  }
}

export class BlockContent extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,

    // убирает боковые padding
    noPaddingLR: PropTypes.bool,

    // убирает верхний padding
    noPaddingT: PropTypes.bool,

    // убирает нижний padding
    noPaddingB: PropTypes.bool,

    // полностью убирает padding
    noPadding: PropTypes.bool
  };

  render() {
    const { className, children, noPaddingLR, noPaddingT, noPaddingB, noPadding } = this.props;
    const mods = { noPaddingLR, noPaddingT, noPaddingB, noPadding };

    return (
      <div
        {...classes('content', mods, className)}
        children={children}
      />
    );
  }
}

export class BlockFooter extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const { children, className } = this.props;
    return (
      <div
        {...classes('footer', null, className)}
        children={children}
      />
    );
  }
}
