import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import map from 'lodash/map';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { I18nHoc } from 'helpers/i18n';
import { iconTypes } from 'components/icon';
import Button, { btnThemes } from 'components/button';
import Block, { BlockHeader, BlockContent } from 'components/block';

import translates from './notification-i18n.json';

import 'styles/base/components/notification/_notification.scss';

let notificationRef = null;
let uniqId = 1;

function formatText(text) {
  if (!isString(text)) return text;

  return map(text.split('\n'), (part, index) => (<div key={index}>{part}</div>));
}

/**
 * Notification display system
 */
@I18nHoc(translates)
class Notification extends Component {
  /**
   * Show message
   * @param title
   * @param message
   * @param level
   * @param uid
   * @param callback
   * @param position
   * @param dismiss
   * @param callbackBtnProps
   * @private
   */
  static _msg(title, message, level, uid, callback, position, dismiss, callbackBtnProps) {
    if (!notificationRef) return null;

    uniqId += 1;
    notificationRef.addNotification({
      level,
      uid,
      dismissible: false,
      position: position || 'bl',
      autoDismiss: callback ? 0 : dismiss,
      children:
        !message ? (
          <Block className="notification__item notification__item_noMessage">
            <BlockHeader className={`notification__head notification__head_${level}`}>
              <Button
                className="notification__btn"
                onClick={() => notificationRef.removeNotification(uid)}
                iconType={iconTypes.clear}
                noPadding
              />
            </BlockHeader>
            <BlockContent className="notification__content">
              <div
                className="notification__message"
                data-test="notificationMessage"
                children={formatText(title)}
              />
            </BlockContent>
          </Block>
        ) : (
          <Block className="notification__item">
            <BlockHeader
              className={`notification__head notification__head_${level}`}
              headerTopic={title}
              headerRight={
                <Button
                  className="notification__btn"
                  onClick={() => notificationRef.removeNotification(uid)}
                  iconType={iconTypes.clear}
                  noPadding
                  data-test="clearButton"
                />
              }
            />
            <BlockContent className="notification__content">
              <div
                className="notification__message"
                data-test="notification__message"
                children={formatText(message)}
              />
              {callback &&
                <div className="notification__message_controls" data-test="notificationMessageControls">
                  <Button
                    className="notification__btn"
                    label={get(callbackBtnProps, 'okText') || 'Да'}
                    onClick={() => {
                      callback();
                      return notificationRef.removeNotification(uid);
                    }}
                    theme={btnThemes.red}
                    data-test="deleteButton"
                  />
                  <Button
                    className="notification__btn"
                    label={get(callbackBtnProps, 'cancelText') || 'Отмена'}
                    onClick={() => notificationRef.removeNotification(uid)}
                    theme={btnThemes.light}
                    data-test="cancelButton"
                  />
                </div>
              }
            </BlockContent>
          </Block>
        ),
    });
  }

  static titleChecker(title, ...args) {
    if (!title) return false;

    Notification._msg(title, ...args);
  }

  static error(title, message, timeUser) {
    let time = 5;
    const cLen = message || title;

    if (cLen && !timeUser) {
      if (cLen.length > 200) {
        time = 40;
      } else if (cLen.length > 100) {
        time = 20;
      }
    }

    Notification.titleChecker(title, message, 'error', `error-${uniqId + 1}`, null, null, timeUser || time);
  }

  static warning(title, message) {
    Notification.titleChecker(title, message, 'warning', `warning-${uniqId + 1}`, null, null, 10);
  }

  static success(title, message, time = 3) {
    Notification.titleChecker(title, message, 'success', `success-${uniqId + 1}`, null, null, time);
  }

  static info(title, message) {
    Notification.titleChecker(title, message, 'info', `info-${uniqId + 1}`, null, null, 15);
  }

  static confirm(title, message, callback, callbackBtnProps) {
    Notification.titleChecker(title, message, 'warning', `confirm-${uniqId + 1}`, callback, 'tc', 0, callbackBtnProps);
  }

  render() {
    const style = {
      // Wrapper: {},
      Containers: {
        DefaultStyle: {
          width: 'auto',
          maxWidth: 'none',
          padding: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
          marginLeft: 'auto'
        },
        bl: {
          height: '100vh',
          top: '0',
          // left: 0,
          margin: 'auto',
          marginLeft: 'auto'
        }
      },
      NotificationItem: {
        DefaultStyle: {
          display: 'flex',
          cursor: 'default',
          borderTop: 0,
          backgroundColor: 'transparent',
          padding: 0,
          fontSize: 'unset',
          borderRadius: 0,
          width: 'auto',
          color: 'inherit',
          margin: 'auto',
          boxShadow: 'none'
        }
        // success: {}
      }
    };

    return (
      <NotificationSystem
        ref={el => notificationRef = el}
        style={style}
      />
    );
  }
}

export default Notification;
