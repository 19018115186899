/**
 * Created by PIV on 15.08.2016.
 */
import { createStore, applyMiddleware, compose } from 'redux'; //
import { reducer as asyncReducer } from 'redux-connect';
import thunkMiddleware from 'redux-thunk';
// import createLogger from 'redux-logger';
import reduxImmutableDevOnly from 'redux-immutable-state-invariant';
import reduxUnhandledAction from 'redux-unhandled-action';
import { routerReducer, routerMiddleware } from 'react-router-redux';
// import { routeParamsReducer } from 'react-router-redux-params';

import dropDownReducer from 'components/drop-down/redux';
import elementGeometryReducer from 'components/element-geometry/redux';
import authReducer from 'auth/redux';
import geolocationReducer from 'geolocation/redux';
import visualStateReducer from 'visual-state/redux';
import geolandingsReducer from 'subsys/geo-landings/redux';
import notificationReducer from 'subsys/notifications/redux';

import ReducersRegistry from './reducers-registry';
import apiMiddleware from './api-middleware';

// import ReducersRegistry from './reducers-registry';
// https://www.npmjs.com/package/redux-async-initial-state
// import analytics from 'redux-analytics'; // https://github.com/markdalgleish/redux-analytics
// import rootReducer from '../reducers'

// const reducers = combineReducers({ routing: routerReducer });

export default function configureStore(history, api, preLoadedState) {
  const dev = __DEVELOPMENT__ || false;

  /*
   * Configure Redux MiddleWares
   */

  const middlewares = [];

  middlewares.push(thunkMiddleware);
  middlewares.push(routerMiddleware(history));
  middlewares.push(apiMiddleware(api));

  if (dev) middlewares.push(reduxImmutableDevOnly());
  if (dev) middlewares.push(reduxUnhandledAction());
  // if (dev) middlewares.push(createLogger());

  /*
   * Configure Reducers Registry
   */
  const reducersRegistry = new ReducersRegistry();
  reducersRegistry.register({ reduxAsyncConnect: asyncReducer });
  reducersRegistry.register({ routing: routerReducer });
  reducersRegistry.register({ auth: authReducer });
  reducersRegistry.register({ geolocation: geolocationReducer });
  reducersRegistry.register({ geolandings: geolandingsReducer });
  reducersRegistry.register({ notification: notificationReducer });
  reducersRegistry.register({ dropDown: dropDownReducer });
  reducersRegistry.register({ elementsGeometries: elementGeometryReducer });
  reducersRegistry.register({ visualState: visualStateReducer });

  /*
   * Configure Redux Store
   */
  let storeModifiers = applyMiddleware(...middlewares);
  if (dev && __CLIENT__ && typeof window === 'object'
    && typeof window.devToolsExtension !== 'undefined') {
    storeModifiers = compose(storeModifiers, window.devToolsExtension());
  }
  const store = createStore(
    reducersRegistry.getCombinedReducers(),
    preLoadedState, storeModifiers
  );

  reducersRegistry.setChangeListener(
    combinedReducers => store.replaceReducer(combinedReducers)
  );

  return store;
}

//  if (__DEVELOPMENT__ && module.hot) {
/* module.hot.accept('./ducks/core', () => {
 var nextCoreReducers = require('./ducks/core')
 reducerRegistry.register(nextCoreReducers)
 }) */
// Enable Webpack hot module replacement for reducers
/* module.hot.accept('../reducers', () => {
 store.replaceReducer(reducers);
 }); */
//  }

// middlewares.push(analytics(({ type, payload }, state) => null));

/* analitics
 *const action = {
 *  type: 'MY_ACTION',
 *  meta: {
 *    analytics: {
 *      type: 'my-analytics-event',
 *      payload: {
 *        some: 'data',
 *        more: 'stuff'
 *      }
 *    }
 *  }
 *};
 */
