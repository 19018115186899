/**
 * Created by Vit on 03.11.2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import 'styles/base/components/loader/_loader.scss';

const classes = new Bem('loader');

/**
 * === Loader Component ===
 * Отображение загрузки поверх компонента
 *
 * = Example =
 * <Loader {...classes('loader')} formMainSize />
 *
 */

export default class Loader extends Component {
  static propTypes = {
    className: PropTypes.string,
    // monochrome balls color scheme
    gray: PropTypes.bool,

    // colored balls
    color: PropTypes.bool,

    // default balls size for using in form - 2.5rem
    formMainSize: PropTypes.bool
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { className, gray, color, formMainSize } = this.props;
    return (
      <div {...classes(null, { gray, color, formMainSize }, className)}>
        <div {...classes('list')}>
          <span {...classes('ball_1')} />
          <span {...classes('ball_2')} />
          <span {...classes('ball_3')} />
        </div>
      </div>
    );
  }
}
