import isError from 'lodash/isError';
/**
 * API Request Error
 */
export default class ApiError extends Error {
  constructor(error, info = {}) {
    super(error);

    if (isError(error)) {
      this.stack = error.stack;
    }

    this.name = 'API Request Error';
    this.isApiError = true;
    this.responseData = null;
    this.requestData = null;
    this.info = info;

    // Collect backend response data for additional reporting
    if (error && error.response) {
      const backendData = error.response.data;
      if (backendData) {
        this.responseData = backendData;
        if (backendData.error) {
          this.message = backendData.error.message;
        }
      }
    }

    // Collect request data
    if (error && error.config) {
      let rData = error.config.data;
      const rUrl = error.config.url;
      try {
        rData = JSON.parse(rData);
      } catch (_e) {
        rData = error.config.data;
      }
      this.message = `${this.message} [${error.config.method} ${rUrl}]`;
      this.requestData = {
        url: rUrl || '[UNKNOWN URL]',
        requestParams: rData
      };
    }
  }
}
