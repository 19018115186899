/**
 * Created by PIV on 04.08.2016.
 */
/* eslint-disable no-param-reassign */

export default {
  req: (req) => {
    const headers = {};

    headers['X-Requested-With'] = 'XMLHttpRequest';

    /* if (!req.postData) {
     headers['Content-Type'] = 'application/json';
     } */

    req.headers = {
      ...headers,
      ...req.headers,
    };
  },
  res: (apiResult) => {
    const { data, headers: { expires } = {} } = apiResult;
    // eslint-disable-next-line no-param-reassign

    // *** I don't know, what is this needed?
    if (data && expires) apiResult.data.expires = new Date(expires).getTime();
  }
};
