/**
 * Created by Vit on 20.09.2016.
 */
/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import CommonError from 'errors/commonError';

import { fontMaps } from './icon-constants';

import 'styles/base/components/icon/_icon.scss';

const classes = new Bem('icon');

/**
 * === Icon Component ===
 * Renders needed icon.
 *
 * = Example =
 * <Icon type={icon type} />
 *
 */
export default class Icon extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape({}),

    // Pass icon name/type needed to render
    type: PropTypes.string,

    // Pass icon title
    title: PropTypes.string,

    // icon children
    substrate: PropTypes.string
  };

  static cache = {};

  render() {
    const { className, style, type, title: unsafeTitle, substrate } = this.props;
    const title = (typeof unsafeTitle === 'string') ? unsafeTitle : null;

    if (!type) {
      throw new CommonError(`Передан несуществующий тип иконки - ${type}`);
    }

    return (
      <span
        {...classes(null, type, className)}
        dangerouslySetInnerHTML={{ __html: `${substrate || ''}${fontMaps[type]}` }}
        style={style}
        title={title}
      />
    );
  }
}
