import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Link from 'components/link';

import img404 from './assets/domik/404.svg';

import translates from './not-found-i18n.json';

const bemClasses = new Bem('pageNotFound');

@I18nHoc(translates)
export default class ContentForum extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { i18n } = this;

    return (
      <div {...bemClasses({ extra: this.props.className })}>
        <div {...bemClasses('imgContainer')}>
          <img {...bemClasses('imgMain')} src={img404} alt="404" />
        </div>
        <div {...bemClasses('topicContainer')}>
          <div {...bemClasses('text', 'h1')}><h1 children={i18n('domikError404')} /></div>
        </div>
        <div {...bemClasses('descriptionContainer')}>
          <span {...bemClasses('text', 'description')}><p children={i18n('domikError404Description')} /></span>
        </div>
        <div {...bemClasses('linkContainer')}>
          <Link
            {...bemClasses('link')}
            href="/"
            title={i18n('domikToMainTitle')}
          >
            <span {...bemClasses('linkContent')}>
              <span {...bemClasses('linkShadow')} />
              <span {...bemClasses('text', 'link')} children={i18n('domikToMain')} />
            </span>
          </Link>
        </div>
      </div>
    );
  }
}
