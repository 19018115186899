/**
 * Created by PIV on 04.08.2016.
 */
import browserCookies from 'browser-cookies';
/* eslint-disable no-param-reassign */
export default function (maybeServerReq) {
  if (__CLIENT__) {
    const token = browserCookies.get('XSRF-TOKEN');

    if (!token) return {};

    return {
      req: (reqConfig) => {
        reqConfig.headers = {
          ...reqConfig.headers,
          'X-XSRF-TOKEN': encodeURIComponent(token)
        };
        return reqConfig;
      }
    };
  }

  if (__SERVER__) {
    const { cookies: { 'XSRF-TOKEN': token } = {} } = maybeServerReq || {};

    if (!token) return {};

    return {
      req: (reqConfig) => {
        reqConfig.headers = {
          ...reqConfig.headers,
          'X-XSRF-TOKEN': token
        };
        return reqConfig;
      }
    };
  }

  return {};
}
