import HttpStatus from 'exceptions/httpStatus';
import DataProvider from './data-provider';
import config from '../../config';

export function performRedirect(to) {
  let href = null;
  if (to.from_project !== to.to_project) {
    href = `//${config.domains[to.to_project]}${to.to_url}${to.query || ''}`;
  } else {
    href = `${to.to_url}${to.query || ''}`;
  }
  if (__CLIENT__) {
    location.href = href;
  } else {
    throw new HttpStatus(301, href);
  }
}

/**
 * Check for redirect in redirects database, and
 * send 301 response if it found.
 * Uses in 404 page, and can be used manually if it needed
 */
export default function () {
  return {
    $redirectReq: [DataProvider.preload, {
      url: 'service/redirect',
      query: {
        fromProject: __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__,
        fromUrl: '$route.path',
      }
    }],
    $redirectChk: {
      $wait: ['$redirectReq'],
      $callback(response) {
        const to = response.$redirectReq.data[0];
        if (to) {
          performRedirect(to);
        }
      }
    }
  };
}
