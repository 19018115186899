/**
 * Created by Kotkin on 21.12.2017.
 */
import { withDefaults } from 'react-bem-helper';

// https://github.com/marcohamersma/react-bem-helper

/**
 * === Bem Helper ===
 *
 * When executed, the helper returns an object with a className property.
 * When the helper is called without any arguments,
 * its value will consist of the block name (prefixed if applicable)
 *
 * = Example General =
 * import Bem from 'helpers/bem';
 * const classes = new Bem('componentName');
 *
 * <div {...classes('element', 'modifier', 'extra')} />
 * <div
 *   {...classes({
 *     element: 'element',
 *     modifiers: 'modifier',
 *     extra: 'extra'
 *   })}
 * />
 * <- Returns <div className='componentName__element componentName__element--modifier extra'/> ->
 *
 * = Parameters =
 *  1. element - String;
 *  2. modifiers - String || ArrayOf[String] || {};
 *  3. element - String || ArrayOf[String] || {};
 */

/**
 * = Modifiers =
 * const bemHelper = new Bem('componentName);
 *
 * bemHelper(null, 'active');
 * bemHelper({ modifiers: 'active' });
 * <- Returns { className: 'componentName--active' } ->
 *
 * bemHelper('lol', 'active funny');
 * <- Returns {
 *   className: 'componentName__lol componentName__lol--active componentName__lol--funny'
 * } ->
 *
 * bemHelper('lol', 'active');
 * <- Returns { className: 'componentName__lol componentName__lol--active' } ->
 *
 * bemHelper('lol', ['active', 'funny']);
 * <- Returns {
 *   className: 'componentName__lol componentName__lol--active componentName__lol--funny'
 * } ->
 *
 * bemHelper('lol', {
 *   'active': true,
 *   'funny': false,
 *   'playing': function() { return false; }
 *   'stopped notfunny': function() { return true; }
 * });
 * <- Returns {
 *   className: 'componentName__lol
 *               componentName__lol--active
 *               componentName__lol--stopped
 *               componentName__lol--notfunny'
 * } ->
 */

/**
 * = Extra classes =
 * const bemHelper = new Bem('componentName);
 *
 * bemHelper('', '', ['one', 'two']);
 * bemHelper({ extra: ['one', 'two'] });
 * <- Returns { className: 'componentName one two' } ->
 *
 * bemHelper('', '', {
 *   active: true,
 *   funny: false,
 *   playing: function() { return false; }
 * });
 * <- Returns { className: 'componentName active' } ->
 */

/**
 * === withDefaults ===
 * = Options =
 *  1. name - BEM block name. Required;
 *  2. prefix - A prefix for the block name;
 *  3. modifierDelimiter - Separator between element name and modifier name;
 *  4. outputIsString - Whether to return an object or a plain string from helper.
 *
 */

// Set default props globally for project
export default withDefaults({
  modifierDelimiter: '_'
});
