/**
 * Created by sem on 09.06.17.
 */
import each from 'lodash/each';

export default function linksModifier(data) {
  const meowData = {}; // 😸
  each(data, (item) => {
    meowData[item.content_type] = item;
    if (item.children) {
      meowData[item.content_type].children = linksModifier(item.children);
    }
  }); // <== 🐈 🐈 🐈 🐈 🐈 🐈 🐈 🐈 🐈 🐈 🐈
  return meowData; // 😸 😸 😸 😸 😸 😸 😸 😸
}
