import React, { Component, Fragment, Children } from 'react';
import PropTypes from 'prop-types';

export default class NoIndex extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;
    if (Children.count(children) === 0) return null;

    return (
      <Fragment>
        <span
          className="noIndex"
          dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} // eslint-disable-line
        />
        {children}
        <span
          className="noIndex"
          dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} // eslint-disable-line
        />
      </Fragment>
    );
  }
}
