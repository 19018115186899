import update from 'react-addons-update';

const LOAD = 'notifications/LOAD';
const LOAD_SUCCESS = 'notifications/LOAD_SUCCESS';
const LOAD_FAILURE = 'notifications/LOAD_FAILURE';

const initialState = {
  action: null,
  loading: false,
  loaded: false,
  failure: false,
  count: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: true },
        failure: { $set: false },
      });

    case LOAD_SUCCESS:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        loaded: { $set: true },
        failure: { $set: false },
        count: { $set: action.payload }
      });

    case LOAD_FAILURE:
      return update(state, {
        action: { $set: action.type },
        loading: { $set: false },
        failure: { $set: true },
      });

    default:
      return state;
  }
}

export function actionGetNotifyData() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAILURE],
    apiRequest: {
      method: 'get',
      url: '/action/user/action-common/unread-notifications-count'
    }
  };
}
